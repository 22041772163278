<template>
  <v-container class="d-flex flex-column">
    <v-row class="fill-height" v-if="$_isAnyAudioSegmentSelected">
      <v-col cols="4" class="d-flex flex-column">
        <label class="caption">
          Begin
          <span class="time-unit">{{ $_timeUnitSuffix }}</span>
        </label>
        <editable-text
          v-model="$_selectedAudioSegmentBegin"
          v-bind:readonly="$_isSelectedAudioSegmentBeginReadonly"
          v-bind:validator="$_beginValidator"
        >
        </editable-text>
        <scoped-waveform-canvas-container
          begin
          v-if="$_isSingleSegmentSelected"
          v-model="$_audioSegmentSequence"
          v-bind:waveform-digest="waveformDigest"
          v-bind:audio-segment-idx="$_singlySelectedSegmentIdx"
          v-bind:num-context-samples="$data.$_numScopedWaveformContextSamples"
          v-bind:readonly="$_isSelectedAudioSegmentBeginReadonly"
          v-bind:control-mode="controlMode"
          v-bind:shift-key-pressed="shiftKeyPressed"
          v-bind:sample-offset="$data.$_beginSampleOffset"
          v-on:register-component-instance="$_registerBeginWaveformCanvasContainerInstance"
          v-on:unregister-component-instance="$_unregisterBeginWaveformCanvasContainerInstance"
          v-on:update="$_onUpdate"
          v-on:update-num-context-samples="$_onUpdateNumContextSamples"
          v-on:offset="$_onOffsetBegin"
        >
        </scoped-waveform-canvas-container>
      </v-col>

      <v-col cols="4" class="d-flex flex-column">
        <label class="caption">Label</label>
        <editable-text
          class="text-field"
          v-model="$_selectedLabel"
        >
        </editable-text>
        <audio-segment-waveform-canvas-container
          v-if="$_isSingleSegmentSelected"
          v-bind:waveform-digest="waveformDigest"
          v-bind:audio-segment-sequence="audioSegmentSequence"
          v-bind:audio-segment-idx="$_singlySelectedSegmentIdx"
          v-bind:begin-sample-offset="$data.$_beginSampleOffset"
          v-bind:end-sample-offset="$data.$_endSampleOffset"
          v-on:register-component-instance="$_registerAudioSegmentWaveformCanvasContainerInstance"
          v-on:unregister-component-instance="$_unregisterAudioSegmentWaveformCanvasContainerInstance"
        >
        </audio-segment-waveform-canvas-container>
      </v-col>

      <v-col cols="4" class="d-flex flex-column">
        <label class="caption">
          End
          <span class="time-unit">{{ $_timeUnitSuffix }}</span>
        </label>
        <editable-text
          v-model="$_selectedAudioSegmentEnd"
          v-bind:readonly="$_isSelectedAudioSegmentEndReadonly"
          v-bind:validator="$_endValidator"
        >
        </editable-text>
        <scoped-waveform-canvas-container
          end
          v-if="$_isSingleSegmentSelected"
          v-model="$_audioSegmentSequence"
          v-bind:waveform-digest="waveformDigest"
          v-bind:audio-segment-idx="$_singlySelectedSegmentIdx"
          v-bind:num-context-samples="$data.$_numScopedWaveformContextSamples"
          v-bind:readonly="$_isSelectedAudioSegmentEndReadonly"
          v-bind:control-mode="controlMode"
          v-bind:shift-key-pressed="shiftKeyPressed"
          v-bind:sample-offset="$data.$_endSampleOffset"
          v-on:register-component-instance="$_registerEndWaveformCanvasContainerInstance"
          v-on:unregister-component-instance="$_unregisterEndWaveformCanvasContainerInstance"
          v-on:update="$_onUpdate"
          v-on:update-num-context-samples="$_onUpdateNumContextSamples"
          v-on:offset="$_onOffsetEnd"
        >
        </scoped-waveform-canvas-container>
      </v-col>
    </v-row>

    <v-row align="center" v-else>
      <v-col cols="12" align="center" justify="center">
        No audio segment is selected.
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
span.time-unit {
  font-size: x-small;
  margin-left: 3px;
}
</style>

<script>
import AudioSegmentSequence, { AudioSegmentTimeUnit } from '../modules/AudioSegmentSequence.js';
import ScopedWaveformCanvasContainer from './ScopedWaveformCanvasContainer.vue';
import AudioSegmentWaveformCanvasContainer from './AudioSegmentWaveformCanvasContainer.vue';
import WaveformDigest from '../modules/WaveformDigest.js';
import EditableText from '../EditableText.vue';
import RationalNumber from '../modules/RationalNumber.js';
import AudioPlaybackLoopDefinition from '../AudioPlayer/modules/AudioPlaybackLoopDefinition.js';
import ControlMode from '../modules/ControlMode.js';
import Utils from '../modules/Utils.js';

export default {
  components: {
    EditableText,
    ScopedWaveformCanvasContainer,
    AudioSegmentWaveformCanvasContainer,
  },

  model: {
    prop: 'audioSegmentSequence',
    event: 'update',
  },

  watch: {
    isAutoFollowLoopDefinitionEnabled: {
      handler(isAutoFollowLoopDefinitionEnabled) {
        if (isAutoFollowLoopDefinitionEnabled) {
          this.$_followAudioPlaybackLoopDefinition(this.audioSegmentSequence, this.$_singlySelectedSegmentIdx)
        }
      },
      immediate: true,
    },

    $_singlySelectedSegmentIdx: {
      handler(singlySelectedSegmentIdx) {
        if (this.isAutoFollowLoopDefinitionEnabled) {
          this.$_followAudioPlaybackLoopDefinition(this.audioSegmentSequence, singlySelectedSegmentIdx)
        }
      },
      immediate: true,
    },

    audioSegmentSequence: {
      handler(audioSegmentSequence) {
        if (this.isAutoFollowLoopDefinitionEnabled) {
          this.$_followAudioPlaybackLoopDefinition(audioSegmentSequence, this.$_singlySelectedSegmentIdx)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  props: {
    audioSegmentSequence:              { type: AudioSegmentSequence },
    labelSequence:                     { type: Array },
    selectedAudioSegmentIdcs:          { type: Array, default: null },
    timeUnit:                          { type: AudioSegmentTimeUnit },
    waveformDigest:                    { type: WaveformDigest },
    isAutoFollowLoopDefinitionEnabled: { type: Boolean },
    controlMode:                       { type: ControlMode },
    shiftKeyPressed:                   { type: Boolean },
  },

  data() {
    return {
      $_numScopedWaveformContextSamples: 1000,
      $_beginSampleOffset: 0,
      $_endSampleOffset: 0,
      $_beginWaveformCanvasContainerInstance: null,
      $_endWaveformCanvasContainerInstance: null,
      $_audioSegmentWaveformCanvasContainerInstance: null,
    };
  },

  computed: {
    $_timeUnitSuffix() {
      switch (this.timeUnit) {
        case AudioSegmentSequence.TimeUnit.second:
          return '[sec]'
        case AudioSegmentSequence.TimeUnit.millisecond:
          return '[msec]'
        case AudioSegmentSequence.TimeUnit.microsecond:
          return '[μsec]'
        case AudioSegmentSequence.TimeUnit.sample:
          return '[sample]'
        default:
          return null;
      }
    },

    $_audioSegmentSequence: {
      get()                     { return this.audioSegmentSequence },
      set(audioSegmentSequence) { this.$emit('update', audioSegmentSequence) },
    },

    $_isAnyAudioSegmentSelected() {
      return (this.selectedAudioSegmentIdcs.length > 0);
    },

    $_isSingleSegmentSelected() {
      return (this.selectedAudioSegmentIdcs.length === 1);
    },

    $_singlySelectedSegmentIdx() {
      if (!this.$_isSingleSegmentSelected) return null;
      return this.selectedAudioSegmentIdcs[0];
    },

    $_singlySelectedAudioSegment() {
      if (this.$_singlySelectedSegmentIdx === null) return null;
      return this.audioSegmentSequence.audioSegments[this.$_singlySelectedSegmentIdx];
    },

    $_selectedLabel: {
      get() {
        let selectedLabels = new Array();
        for (let selectedAudioSegmentIdx of this.selectedAudioSegmentIdcs) {
          let selectedLabel = this.labelSequence[selectedAudioSegmentIdx];
          if (!selectedLabels.includes(selectedLabel)) selectedLabels.push(selectedLabel);
        }
        if (selectedLabels.length === 1) return selectedLabels[0];
        return '';
      },

      set(audioSegmentLabel) {
        let newLabelSequence = Utils.cloneArray(this.labelSequence);
        for (let selectedAudioSegmentIdx of this.selectedAudioSegmentIdcs) {
          newLabelSequence[selectedAudioSegmentIdx] = audioSegmentLabel
        }
        this.$emit('update-label-sequence', newLabelSequence);
      },
    },

    $_isSelectedAudioSegmentBeginReadonly() {
      if (!this.$_isSingleSegmentSelected) return true;
      if (this.$_singlySelectedSegmentIdx !== 0) return false;
      return true;
    },

    $_isSelectedAudioSegmentEndReadonly() {
      if (!this.$_isSingleSegmentSelected) return true;
      if (this.$_singlySelectedSegmentIdx !== (this.audioSegmentSequence.numAudioSegments - 1)) return false;
      return true;
    },

    $_beginValidator() {
      if (this.$_isSingleSegmentSelected) {
        return this.audioSegmentSequence.generateBeginValidator(this.$_singlySelectedSegmentIdx, this.timeUnit);
      } else {
        return undefined;
      }
    },

    $_endValidator() {
      if (this.$_isSingleSegmentSelected) {
        return this.audioSegmentSequence.generateEndValidator(this.$_singlySelectedSegmentIdx, this.timeUnit);
      } else {
        return undefined;
      }
    },

    $_selectedAudioSegmentBegin: {
      get() {
        if (this.$_singlySelectedAudioSegment === null) return '(multiple values)';
        let audioSegmentBegin = this.audioSegmentSequence.convertTime(
          this.$_singlySelectedAudioSegment.begin,
          this.timeUnit,
        );
        let beginOffset = AudioSegmentSequence.convertTime(
          RationalNumber.generateFrom(Math.floor(this.$data.$_beginSampleOffset)),
          AudioSegmentSequence.TimeUnit.sample,
          this.timeUnit,
          { samplingRate: this.audioSegmentSequence.samplingRate },
        );
        return audioSegmentBegin.add(beginOffset).toNumber();
      },

      set(audioSegmentBegin) {
        if (this.$_isSelectedAudioSegmentBeginReadonly) return;
        let audioSegmentBeginWithoutOffset = audioSegmentBegin - this.$data.$_beginSampleOffset;
        let newAudioSegmentSequence = this.audioSegmentSequence.clone();
        newAudioSegmentSequence.replaceSegmentBegin(
          this.$_singlySelectedSegmentIdx,
          RationalNumber.generateFrom(Math.floor(audioSegmentBeginWithoutOffset)),
          this.timeUnit,
        );
        this.$emit('update', newAudioSegmentSequence);
      },
    },

    $_singlySelectedAudioSegmentBeginSampleOffset() {
      if (this.$_singlySelectedAudioSegment === null) return null;
      let audioSegmentBegin = this.$_singlySelectedAudioSegment.begin;
      return this.audioSegmentSequence.convertTime(audioSegmentBegin, AudioSegmentSequence.TimeUnit.sample).toNumber();
    },

    $_selectedAudioSegmentEnd: {
      get() {
        if (this.$_singlySelectedAudioSegment === null) return '(multiple values)';
        let audioSegmentEnd = this.audioSegmentSequence.convertTime(
          this.$_singlySelectedAudioSegment.end,
          this.timeUnit,
        );
        let endOffset = AudioSegmentSequence.convertTime(
          RationalNumber.generateFrom(Math.floor(this.$data.$_endSampleOffset)),
          AudioSegmentSequence.TimeUnit.sample,
          this.timeUnit,
          { samplingRate: this.audioSegmentSequence.samplingRate },
        );
        return audioSegmentEnd.add(endOffset).toNumber();
      },

      set(audioSegmentEnd) {
        if (this.$_isSelectedAudioSegmentEndReadonly) return;
        let audioSegmentEndWithoutOffset = audioSegmentEnd - this.$data.$_endSampleOffset;
        let newAudioSegmentSequence = this.audioSegmentSequence.clone();
        newAudioSegmentSequence.replaceSegmentEnd(
          this.$_singlySelectedSegmentIdx,
          RationalNumber.generateFrom(audioSegmentEndWithoutOffset),
          this.timeUnit,
        );
        this.$emit('update', newAudioSegmentSequence);
      },
    },

    $_singlySelectedAudioSegmentEndSampleOffset() {
      if (this.$_singlySelectedAudioSegment === null) return null;
      let audioSegmentEnd = this.$_singlySelectedAudioSegment.end;
      return this.audioSegmentSequence.convertTime(audioSegmentEnd, AudioSegmentSequence.TimeUnit.sample).toNumber();
    },
  },

  mounted() {
    this.$data.$_numScopedWaveformContextSamples = this.audioSegmentSequence.samplingRate.toNumber();
  },

  inject: [
    'setAudioPlaybackLoopDefinition',
    'resetAudioPlaybackLoopDefinition',
  ],

  methods: {
    $_registerBeginWaveformCanvasContainerInstance(beginWaveformCanvasContainerInstance) {
      this.$data.$_beginWaveformCanvasContainerInstance = beginWaveformCanvasContainerInstance;
    },

    $_unregisterBeginWaveformCanvasContainerInstance() {
      this.$data.$_beginWaveformCanvasContainerInstance = null;
    },

    $_registerEndWaveformCanvasContainerInstance(endWaveformCanvasContainerInstance) {
      this.$data.$_endWaveformCanvasContainerInstance = endWaveformCanvasContainerInstance;
    },

    $_unregisterEndWaveformCanvasContainerInstance() {
      this.$data.$_endWaveformCanvasContainerInstance = null;
    },

    $_registerAudioSegmentWaveformCanvasContainerInstance(audioSegmentWaveformCanvasContainerInstance) {
      this.$data.$_audioSegmentWaveformCanvasContainerInstance = audioSegmentWaveformCanvasContainerInstance;
    },

    $_unregisterAudioSegmentWaveformCanvasContainerInstance() {
      this.$data.$_audioSegmentWaveformCanvasContainerInstance = null;
    },

    onMousemove(mouseEvent) {
      if (this.$data.$_beginWaveformCanvasContainerInstance !== null) {
        this.$data.$_beginWaveformCanvasContainerInstance.onMousemove(mouseEvent);
      }
      if (this.$data.$_endWaveformCanvasContainerInstance !== null) {
        this.$data.$_endWaveformCanvasContainerInstance.onMousemove(mouseEvent);
      }
    },

    onMouseup(mouseEvent) {
      if (this.$data.$_beginWaveformCanvasContainerInstance !== null) {
        this.$data.$_beginWaveformCanvasContainerInstance.onMouseup(mouseEvent);
      }
      if (this.$data.$_endWaveformCanvasContainerInstance !== null) {
        this.$data.$_endWaveformCanvasContainerInstance.onMouseup(mouseEvent);
      }
    },

    $_onUpdate(updatedAudioSegmentSequence) {
      if (this.$_singlySelectedSegmentIdx === null) return;
      if (this.isAutoFollowLoopDefinitionEnabled) {
        this.$_followAudioPlaybackLoopDefinition(updatedAudioSegmentSequence, this.$_singlySelectedSegmentIdx);
      }
    },

    $_onUpdateNumContextSamples(numContextSamples) {
      this.$data.$_numScopedWaveformContextSamples = numContextSamples;
    },

    $_onOffsetBegin(beginSampleOffset) {
      this.$data.$_beginSampleOffset = beginSampleOffset;
    },

    $_onOffsetEnd(endSampleOffset) {
      this.$data.$_endSampleOffset = endSampleOffset;
    },

    $_followAudioPlaybackLoopDefinition(audioSegmentSequence, singlySelectedSegmentIdx) {
      if (singlySelectedSegmentIdx === null) {
        this.resetAudioPlaybackLoopDefinition();
      } else {
        let singlySelectedAudioSegment = audioSegmentSequence.audioSegments[singlySelectedSegmentIdx];
        let loopBeginRationalTime = audioSegmentSequence.convertTime(
          singlySelectedAudioSegment.begin,
          AudioSegmentSequence.TimeUnit.second,
        );
        let loopEndRationalTime = audioSegmentSequence.convertTime(
          singlySelectedAudioSegment.end,
          AudioSegmentSequence.TimeUnit.second,
        );
        this.setAudioPlaybackLoopDefinition(new AudioPlaybackLoopDefinition(
          loopBeginRationalTime.toNumber(),
          loopEndRationalTime.toNumber(),
        ));
      }
    },
  },
}
</script>