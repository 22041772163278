<template>
  <v-container>
    <v-row>
      <v-col class="flex-grow-1">
        <label-sequence-input-field
          v-model="$data.$_labelSequence"
          v-bind:delimiter="$data.$_labelSequenceDelimiter"
          v-bind:label-options="labelOptions"
          v-bind:selected-audio-segment-idcs="$_selectedAudioSegmentIdcs"
        >
        </label-sequence-input-field>
      </v-col>

      <v-col class="flex-grow-0">
        <label-sequence-delimiter-input-field v-model="$data.$_labelSequenceDelimiter">
        </label-sequence-delimiter-input-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
        <time-unit-selector
          class="mb-3"
          v-model="$data.$_timeUnit"
        >
        </time-unit-selector>

        <audio-segment-list
          v-if="$data.$_audioSegmentSequence.samplingRate !== null"
          v-model="$data.$_audioSegmentSequence"
          v-bind:selected-audio-segment-idcs="$_selectedAudioSegmentIdcs"
          v-bind:label-sequence="$data.$_labelSequence"
          v-bind:time-unit="$data.$_timeUnit"
          v-on:update-label-sequence="$_updateLabelSequence"
        >
        </audio-segment-list>
      </v-col>

      <v-col cols="9">
        <audio-player
          v-if="$_isAudioLoaded"
          v-bind:audio-context="$data.$_audioContext"
          v-bind:audio-buffer="$data.$_audioBuffer"
          v-bind:loop-definition="$data.$_audioPlaybackLoopDefinition"
          v-bind:is-loop-enabled="$data.$_isLoopPlaybackEnabled"
          v-on:register-component-instance="$_registerAudioPlayerComponentInstance"
          v-on:unregister-component-instance="$_unregisterAudioPlayerComponentInstance"
          v-on:update-play-time="$_onUpdatePlayTime"
        >
        </audio-player>
        <waveform-view
          v-if="$_isAudioLoaded"
          v-model="$data.$_audioSegmentSequence"
          v-bind:label-sequence="$data.$_labelSequence"
          v-bind:audio-buffer="$data.$_audioBuffer"
          v-bind:waveform-digest="$data.$_waveformDigest"
          v-bind:time-unit="$data.$_timeUnit"
          v-bind:selected-audio-segment-idcs="$_selectedAudioSegmentIdcs"
          v-bind:play-time-sec="$data.$_playTimeSec"
          v-bind:is-undo-disabled="$data.$_isUndoDisabled"
          v-bind:is-redo-disabled="$data.$_isRedoDisabled"
          v-on:register-component-instance="$_registerWaveformViewComponentInstance"
          v-on:unregister-component-instance="$_unregisterWaveformViewComponentInstance"
          v-on:update-label-sequence="$_updateLabelSequence"
        >
        </waveform-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AudioPlayer from './TAudioSegmentation/AudioPlayer.vue';
import WaveformView from './TAudioSegmentation/WaveformView.vue';
import WaveformDigest from './TAudioSegmentation/modules/WaveformDigest.js';
import LabelSequenceInputField from './TAudioSegmentation/LabelSequenceInputField.vue';
import LabelSequenceDelimiterInputField from './TAudioSegmentation/LabelSequenceDelimiterInputField.vue';
import TimeUnitSelector from './TAudioSegmentation/TimeUnitSelector.vue';
import AudioSegmentList from './TAudioSegmentation/AudioSegmentList.vue';
import AudioSegmentSequence from './TAudioSegmentation/modules/AudioSegmentSequence.js';
import RationalNumber from './TAudioSegmentation/modules/RationalNumber.js';
import Utils from './TAudioSegmentation/modules/Utils.js';
import ChangeHistoryManager from './TAudioSegmentation/modules/ChangeHistoryManager.js';

const webAssemblyFileLocation = '/resources/web-assembly';

async function readFileAsUint8Array(filePath) {
  let response = await fetch(filePath);
  let dataLength = Number(response.headers.get('Content-Length'));
  let buffer = new Uint8Array(dataLength);
  let reader = response.body.getReader();
  let writeOffset = 0;
  return await reader.read().then(
    async function writeBackOnBuffer({ done, value }) {
      if (done) return buffer;
      buffer.set(value, writeOffset);
      writeOffset += value.length;
      return await reader.read().then(writeBackOnBuffer);
    }
  );
}

export default {
  components: {
    LabelSequenceInputField,
    LabelSequenceDelimiterInputField,
    TimeUnitSelector,
    AudioSegmentList,
    WaveformView,
    AudioPlayer,
  },

  model: {
    prop: 'audioSegmentSequence',
    event: 'update',
  },

  watch: {
    audioUrl: {
      handler(audioUrl) {
        if (audioUrl === null) return;
        readFileAsUint8Array(audioUrl).then(audioSource => {
          this.$data.$_audioSource = audioSource;
        });
      },
      immediate: true,
    },

    '$data.$_audioBuffer': {
      handler(audioBuffer) {
        this.$data.$_audioSegmentSequence.samplingRate = audioBuffer.sampleRate;
        this.$data.$_audioSegmentSequence.normalize(
          RationalNumber.generateFrom(audioBuffer.length),
          AudioSegmentSequence.TimeUnit.sample,
          { samplingRate: audioBuffer.sampleRate },
        );
        WaveformDigest.loadFromAudioBufferByWebAssembly(audioBuffer, webAssemblyFileLocation)
          .then(waveformDigest => { this.$data.$_waveformDigest = waveformDigest });
      },
    },

    '$data.$_audioSource': {
      handler(audioSource) {
        let copiedAudioSourceBuffer = audioSource.buffer.slice(0);
        let audioContext = new AudioContext();
        audioContext.decodeAudioData(copiedAudioSourceBuffer)
          .then(audioBuffer => { this.$data.$_audioBuffer = audioBuffer });
        audioContext.suspend();
        this.$data.$_audioContext = audioContext;
      },
    },

    audioSegmentSequence: {
      handler(audioSegmentSequence) {
        if (audioSegmentSequence === null) return;
        this.$data.$_audioSegmentSequence = audioSegmentSequence;
      },
      deep: true,
      immediate: true,
    },

    '$data.$_audioSegmentSequence': {
      handler(audioSegmentSequence) {
        this.$data.$_changeHistoryManager.register(audioSegmentSequence);
        this.$data.$_isUndoDisabled = this.$data.$_changeHistoryManager.isFirstSnapshot();
        this.$data.$_isRedoDisabled = this.$data.$_changeHistoryManager.isLastSnapshot();
        let filledLabelSequence = this.$_fillLabelSequence(this.$data.$_labelSequence);
        if (filledLabelSequence !== null) this.$data.$_labelSequence = filledLabelSequence;
        this.$emit('update', audioSegmentSequence);
      },
      deep: true,
    },

    labelSequence: {
      handler(labelSequence) {
        if (labelSequence === null) return;
        let filledLabelSequence = this.$_fillLabelSequence(labelSequence);
        this.$data.$_labelSequence = (filledLabelSequence === null)? labelSequence : filledLabelSequence;
      },
      deep: true,
      immediate: true,
    },

    '$data.$_labelSequence': {
      handler(labelSequence) {
        this.$emit('update:label-sequence', labelSequence);
      },
      deep: true,
    },
  },

  props: {
    audioUrl: { type: String, default: null },
    audioSegmentSequence: { type: AudioSegmentSequence, default: null },
    labelSequence: { type: Array, default: null },
    labelOptions: { type: Array, default: null },
  },

  data() {
    return {
      $_changeHistoryManager: new ChangeHistoryManager(),
      $_audioSegmentSequence: new AudioSegmentSequence(),
      $_labelSequence: new Array(),
      $_labelSequenceDelimiter: ' ',
      $_audioSource: null,
      $_audioContext: null,
      $_audioBuffer: null,
      $_waveformDigest: null,
      $_timeUnit: AudioSegmentSequence.TimeUnit.sample,
      $_playTimeSec: 0,
      $_audioPlaybackLoopDefinition: null,
      $_loopPlaybackEndTimeSec: null,
      $_isLoopPlaybackEnabled: false,
      $_audioPlayerComponentInstance: null,
      $_waveformViewComponentInstance: null,
      $_animtionFrameRequestId: null,
      $_isUndoDisabled: true,
      $_isRedoDisabled: true,
    };
  },

  computed: {
    $_isAudioLoaded() {
      return (this.$data.$_waveformDigest !== null) && (this.$data.$_audioBuffer !== null) && (this.$data.$_audioContext !== null);
    },

    $_selectedAudioSegmentIdcs() {
      let selectedAudioSegmentIdcs = new Array();
      if (this.$data.$_audioSegmentSequence) {
        this.$data.$_audioSegmentSequence.audioSegments.forEach((audioSegment, audioSegmentIdx) => {
          if (audioSegment.isSelected) selectedAudioSegmentIdcs.push(audioSegmentIdx);
        });
      }
      return selectedAudioSegmentIdcs;
    },
  },

  mounted() {
    window.addEventListener('keydown', this.$_onKeydownOnWindow);
    window.addEventListener('keyup', this.$_onKeyupOnWindow);
    window.addEventListener('mousemove', this.$_onMousemove);
    window.addEventListener('mouseup', this.$_onMouseup);
    this.$data.$_animtionFrameRequestId = window.requestAnimationFrame(this.$_onRequestAnimationFrame);
  },

  async destroyed() {
    if (this.$data.$_audioContext) await this.$data.$_audioContext.close();
    window.cancelAnimationFrame(this.$data.$_animtionFrameRequestId);
    window.removeEventListener('mouseup', this.$_onMouseup);
    window.removeEventListener('mousemove', this.$_onMousemove);
    window.removeEventListener('keyup', this.$_onKeyupOnWindow);
    window.removeEventListener('keydown', this.$_onKeydownOnWindow);
  },

  provide() {
    return {
      updateAudioSegments: this.$_updateAudioSegments,
      selectAudioSegments: this.$_selectAudioSegments,
      clearSelectedAudioSegments: this.$_clearSelectedAudioSegments,
      enableLoopPlayback: () => { this.$data.$_isLoopPlaybackEnabled = true },
      disableLoopPlayback: () => { this.$data.$_isLoopPlaybackEnabled = false },
      setAudioPlaybackLoopDefinition: this.$_setAudioPlaybackLoopDefinition,
      resetAudioPlaybackLoopDefinition: this.$_resetAudioPlaybackLoopDefinition,
      undo: this.$_undo,
      redo: this.$_redo,
    };
  },

  methods: {
    $_registerWaveformViewComponentInstance(waveformViewComponentInstance) {
      this.$data.$_waveformViewComponentInstance = waveformViewComponentInstance;
    },

    $_unregisterWaveformViewComponentInstance() {
      this.$data.$_waveformViewComponentInstance = null;
    },

    $_registerAudioPlayerComponentInstance(audioPlayerComponentInstance) {
      this.$data.$_audioPlayerComponentInstance = audioPlayerComponentInstance;
    },

    $_unregisterAudioPlayerComponentInstance() {
      this.$data.$_audioPlayerComponentInstance = null;
    },

    $_onMouseup(mouseEvent) {
      if (this.$data.$_audioPlayerComponentInstance !== null) {
        this.$data.$_audioPlayerComponentInstance.onMouseup(mouseEvent);
      }
      if (this.$data.$_waveformViewComponentInstance !== null) {
        this.$data.$_waveformViewComponentInstance.onMouseup(mouseEvent);
      }
    },

    $_onMousemove(mouseEvent) {
      if (this.$data.$_audioPlayerComponentInstance !== null) {
        this.$data.$_audioPlayerComponentInstance.onMousemove(mouseEvent);
      }
      if (this.$data.$_waveformViewComponentInstance !== null) {
        this.$data.$_waveformViewComponentInstance.onMousemove(mouseEvent);
      }
    },

    $_onKeydownOnWindow(keyboardEvent) {
      let isKeydownEventHooked = this.$_onKeydown(keyboardEvent);
      if (isKeydownEventHooked) keyboardEvent.preventDefault();
    },

    $_onKeydown(keyboardEvent) {
      if (this.$data.$_audioPlayerComponentInstance !== null) {
        if (this.$data.$_audioPlayerComponentInstance.onKeydown(keyboardEvent)) return true;
      }
      if (this.$data.$_waveformViewComponentInstance !== null) {
        if (this.$data.$_waveformViewComponentInstance.onKeydown(keyboardEvent)) return true;
      }
      switch (keyboardEvent.code) {
        case 'Escape':
          return clearSelectedAudioSegments(this);
        case 'ArrowRight':
          return selectNextAudioSegments(this);
        case 'ArrowLeft':
          return selectPreviousAudioSegments(this);
        default:
          return false;
      }

      function clearSelectedAudioSegments(self) {
        if (self.$_selectedAudioSegmentIdcs.length === 0) return false;
        self.$_clearSelectedAudioSegments();
        return true;
      }

      function selectNextAudioSegments(self) {
        if (self.$_selectedAudioSegmentIdcs.length === 0) {
          if (self.$data.$_audioSegmentSequence.numAudioSegments === 0) return false;
          self.$_selectAudioSegments([ 0 ]);
        } else {
          let lastSelectedAudioSegmentIdx = self.$_selectedAudioSegmentIdcs[self.$_selectedAudioSegmentIdcs.length - 1];
          let nextAudioSegmentIdx = lastSelectedAudioSegmentIdx + 1;
          if (nextAudioSegmentIdx >= self.$data.$_audioSegmentSequence.numAudioSegments) return false;
          self.$_clearSelectedAudioSegments();
          self.$_selectAudioSegments([ nextAudioSegmentIdx ]);
        }
        return true;
      }

      function selectPreviousAudioSegments(self) {
        if (self.$_selectedAudioSegmentIdcs.length === 0) {
          if (self.$data.$_audioSegmentSequence.numAudioSegments === 0) return false;
          self.$_selectAudioSegments([ (self.$data.$_audioSegmentSequence.numAudioSegments - 1) ]);
        } else {
          let lastSelectedAudioSegmentIdx = self.$_selectedAudioSegmentIdcs[self.$_selectedAudioSegmentIdcs.length - 1];
          let previousAudioSegmentIdx = lastSelectedAudioSegmentIdx - 1;
          if (previousAudioSegmentIdx < 0) return false;
          self.$_clearSelectedAudioSegments();
          self.$_selectAudioSegments([ previousAudioSegmentIdx ]);
        }
        return true;
      }
    },

    $_onKeyupOnWindow(keyboardEvent) {
      let isKeydownEventHooked = this.$_onKeyup(keyboardEvent);
      if (isKeydownEventHooked) keyboardEvent.preventDefault();
    },

    $_onKeyup(keyboardEvent) {
      if (this.$data.$_waveformViewComponentInstance !== null) {
        if (this.$data.$_waveformViewComponentInstance.onKeyup(keyboardEvent)) return true;
      }
      return false;
    },

    $_onRequestAnimationFrame(timestamp) {
      if (this.$data.$_audioPlayerComponentInstance !== null) {
        this.$data.$_audioPlayerComponentInstance.onRequestAnimationFrame(timestamp);
      }
      this.$data.$_animtionFrameRequestId = window.requestAnimationFrame(this.$_onRequestAnimationFrame)
    },

    $_onUpdatePlayTime(playTimeSec) {
      this.$data.$_playTimeSec = playTimeSec;
    },

    $_updateLabelSequence(labelSequence) {
      this.$data.$_labelSequence = labelSequence;
    },

    $_updateAudioSegments(audioSegments) {
      this.$data.$_audioSegmentSequence.audioSegments = audioSegments;
    },

    $_selectAudioSegments(audioSegmentIdcs) {
      this.$data.$_audioSegmentSequence.audioSegments.forEach((audioSegment, audioSegmentIdx) => {
        if (audioSegmentIdcs.includes(audioSegmentIdx)) audioSegment.isSelected = true;
      });
    },

    $_clearSelectedAudioSegments() {
      this.$data.$_audioSegmentSequence.audioSegments.forEach(audioSegment => { audioSegment.isSelected = false });
    },

    $_fillLabelSequence(labelSequence) {
      if (labelSequence === null) return null;
      if (this.$data.$_audioSegmentSequence === null) return null;
      if (labelSequence.length >= this.$data.$_audioSegmentSequence.audioSegments.length) return null;
      let filledLabelSequence = Utils.cloneArray(labelSequence);
      let numEmptyLabels = this.$data.$_audioSegmentSequence.audioSegments.length - labelSequence.length;
      let emptyLabels = new Array(numEmptyLabels);
      emptyLabels.fill('');
      filledLabelSequence.push(...emptyLabels);
      return filledLabelSequence;
    },

    $_setAudioPlaybackLoopDefinition(audioPlaybackLoopDefinition) {
      this.$data.$_audioPlaybackLoopDefinition = audioPlaybackLoopDefinition;
    },

    $_resetAudioPlaybackLoopDefinition() {
      this.$data.$_audioPlaybackLoopDefinition = null;
    },

    $_undo() {
      let changeHistory = this.$data.$_changeHistoryManager.undo();
      if (changeHistory) {
        this.$_applyChange(changeHistory);
        return true;
      } else {
        return false;
      }
    },

    $_redo() {
      let changeHistory = this.$data.$_changeHistoryManager.redo();
      if (changeHistory) {
        this.$_applyChange(changeHistory);
        return true;
      } else {
        return false;
      }
    },

    $_applyChange(changeHistory) {
      this.$data.$_changeHistoryManager.prohibitRegistration();
      this.$data.$_audioSegmentSequence = changeHistory.audioSegmentSequence;
      this.$nextTick(() => {
        this.$data.$_changeHistoryManager.allowRegistration();
      });
    },
  },
}
</script>