<template>
  <canvas>
  </canvas>
</template>

<script>
import Utils from '../../modules/Utils.js';
import CanvasBase from './mixins/CanvasBase.js';
import WaveformDigest from '../../modules/WaveformDigest.js';
import Color from '../../modules/Color.js';

const waveformColor = new Color(220, 220, 220);
const waveformLineWidth = 1;

export default {
  mixins: [
    CanvasBase,
  ],

  watch: {
    waveformDigest()          { this.$_setDirty(true); },
    canvasBeginSampleOffset() { this.$_setDirty(true); },
    canvasEndSampleOffset()   { this.$_setDirty(true); },
    canvasDurationSec()       { this.$_setDirty(true); },
  },

  props: {
    waveformDigest:          { type: WaveformDigest },
    canvasBeginSampleOffset: { type: Number },
    canvasEndSampleOffset:   { type: Number },
    canvasDurationSec:       { type: Number },
  },

  computed: {
    $_currentWaveformDigest() {
      if (this.canvasDurationSec === 0) return null;
      let suitableSamplingRate = this.$_canvasWidthPx / this.canvasDurationSec;
      return this.waveformDigest.getDigest(suitableSamplingRate);
    },
  },

  methods: {
    draw(canvasElement) {
      let canvasContext = canvasElement.getContext('2d');
      if (!Utils.isNullOrUndefined(this.waveformDigest) && (this.canvasDurationSec > 0)) {
        this.$_drawWaveform(canvasContext, this.waveformDigest);
      }
    },

    $_drawWaveform(canvasContext, waveformDigest) {
      let numChannels = waveformDigest.numChannels;
      let waveformHeightAll = this.$_canvasHeightPx;
      let eachWaveformHeight = waveformHeightAll / numChannels;
      canvasContext.setLineDash([]);
      canvasContext.lineWidth = waveformLineWidth;
      canvasContext.strokeStyle = waveformColor.styleString;
      for (let channelIdx = 0; channelIdx < numChannels; ++channelIdx) {
        let waveformVerticalOffsetPx = getWaveformVerticalOffsetPx(eachWaveformHeight, channelIdx);
        canvasContext.beginPath();
        canvasContext.moveTo(...this.$_dotByDotOffsetCoordArgs(0, waveformVerticalOffsetPx));
        canvasContext.lineTo(...this.$_dotByDotOffsetCoordArgs(this.$_canvasWidthPx, waveformVerticalOffsetPx));
        canvasContext.stroke();
      }
      let eachWaveformAmplitude = eachWaveformHeight / 2;
      for (let channelIdx = 0; channelIdx < numChannels; ++channelIdx) {
        let waveformVerticalOffsetPx = getWaveformVerticalOffsetPx(eachWaveformHeight, channelIdx);
        if (this.$_currentWaveformDigest) {
          let samplingRateConversionFactor = this.$_currentWaveformDigest[0].samplingRate / this.waveformDigest.originalSamplingRate;
          let canvasBeginDigestSampleOffset = this.canvasBeginSampleOffset * samplingRateConversionFactor;
          let canvasEndDigestSampleOffset = this.canvasEndSampleOffset * samplingRateConversionFactor;
          let currentWaveformDigestByChannel = this.$_currentWaveformDigest[channelIdx];
          canvasContext.fillStyle = waveformColor.styleString;
          let initialDigestSampleCanvasOffsetPx = getCanvasLocalOffsetPx(
            canvasBeginDigestSampleOffset,
            canvasBeginDigestSampleOffset,
            canvasEndDigestSampleOffset,
            this.$_canvasWidthPx,
          );
          canvasContext.moveTo(...this.$_dotByDotOffsetCoordArgs(
            initialDigestSampleCanvasOffsetPx,
            waveformVerticalOffsetPx,
          ));
          canvasContext.beginPath();
          for (let currentDigestSampleOffset = canvasBeginDigestSampleOffset; currentDigestSampleOffset <= canvasEndDigestSampleOffset; ++currentDigestSampleOffset) {
            let currentDigestSampleCanvasOffsetPx = getCanvasLocalOffsetPx(
              currentDigestSampleOffset,
              canvasBeginDigestSampleOffset,
              canvasEndDigestSampleOffset,
              this.$_canvasWidthPx,
            );
            let currentDigestSampleIdx = Math.floor(currentDigestSampleOffset);
            let currentDigestSampleVerticalOffsetPx = waveformVerticalOffsetPx - currentWaveformDigestByChannel.max[currentDigestSampleIdx] * eachWaveformAmplitude;
            canvasContext.lineTo(...this.$_dotByDotOffsetCoordArgs(
              currentDigestSampleCanvasOffsetPx,
              currentDigestSampleVerticalOffsetPx,
            ));
          }
          for (let currentDigestSampleOffset = canvasEndDigestSampleOffset; currentDigestSampleOffset >= canvasBeginDigestSampleOffset; --currentDigestSampleOffset) {
            let currentDigestSampleCanvasOffsetPx = getCanvasLocalOffsetPx(
              currentDigestSampleOffset,
              canvasBeginDigestSampleOffset,
              canvasEndDigestSampleOffset,
              this.$_canvasWidthPx,
            );
            let currentDigestSampleIdx = Math.floor(currentDigestSampleOffset);
            let currentDigestSampleVerticalOffsetPx = waveformVerticalOffsetPx - currentWaveformDigestByChannel.min[currentDigestSampleIdx] * eachWaveformAmplitude;
            canvasContext.lineTo(...this.$_dotByDotOffsetCoordArgs(
              currentDigestSampleCanvasOffsetPx,
              currentDigestSampleVerticalOffsetPx,
            ));
          }
          canvasContext.fill();
          canvasContext.strokeStyle = waveformColor.styleString;
          canvasContext.setLineDash([]);
          canvasContext.lineWidth = waveformLineWidth / 2;
          canvasContext.stroke();
        } else {
          let waveformByChannel = waveformDigest.originalWaveform[channelIdx];
          canvasContext.strokeStyle = waveformColor.styleString;
          canvasContext.setLineDash([]);
          canvasContext.lineWidth = waveformLineWidth;
          canvasContext.beginPath();
          for (let currentSampleOffset = this.canvasBeginSampleOffset; currentSampleOffset <= this.canvasEndSampleOffset; ++currentSampleOffset) {
            let currentSampleCanvasOffsetPx = getCanvasLocalOffsetPx(
              currentSampleOffset,
              this.canvasBeginSampleOffset,
              this.canvasEndSampleOffset,
              this.$_canvasWidthPx,
            );
            let currentSampleIdx = Math.floor(currentSampleOffset);
            let currentSampleVerticalOffsetPx = waveformVerticalOffsetPx - waveformByChannel[currentSampleIdx] * eachWaveformAmplitude;
            canvasContext.lineTo(...this.$_dotByDotOffsetCoordArgs(
              currentSampleCanvasOffsetPx,
              currentSampleVerticalOffsetPx,
            ));
          }
          canvasContext.stroke();
        }
      }

      function getWaveformVerticalOffsetPx(eachWaveformHeight, channelIdx) {
        return Math.floor(eachWaveformHeight * (channelIdx + 0.5));
      }

      function getCanvasLocalOffsetPx(sampleOffset, canvasBeginSampleOffset, canvasEndSampleOffset, containerWidthPx) {
        let currentLocalSampleOffset = sampleOffset - canvasBeginSampleOffset;
        let numSamplesInCanvas = canvasEndSampleOffset - canvasBeginSampleOffset;
        let sampleResolution = numSamplesInCanvas / containerWidthPx;
        return currentLocalSampleOffset / sampleResolution;
      }
    },
  },
}
</script>