class ChangeHistoryManager {
  constructor() {
    this.changeHistoryBuffer = new Array();
    this.currentSnapshotIdx = null;
    this.isRegistrationProhibited = false;
  }

  register(audioSegmentSequence) {
    if (this.isRegistrationProhibited) return false;
    let changeHistory = new ChangeHistoryManager.ChangeHistory(audioSegmentSequence);
    if (this.currentSnapshotIdx === null) {
      this.currentSnapshotIdx = 0;
      this.changeHistoryBuffer.push(changeHistory);
    } else {
      if (this.currentChangeHistory.isEqualTo(changeHistory)) return false;
      ++this.currentSnapshotIdx;
      let numFollowingSnapshots = this.changeHistoryBuffer.length - this.currentSnapshotIdx;
      this.changeHistoryBuffer.splice(
        this.currentSnapshotIdx,
        numFollowingSnapshots,
        changeHistory,
      );
    }
    return true;
  }

  isFirstSnapshot() {
    if (this.currentSnapshotIdx === null) return null;
    return (this.currentSnapshotIdx === 0);
  }

  isLastSnapshot() {
    if (this.currentSnapshotIdx === null) return null;
    return (this.currentSnapshotIdx === (this.changeHistoryBuffer.length - 1));
  }

  get currentChangeHistory() {
    if (this.currentSnapshotIdx === null) return null;
    return this.changeHistoryBuffer[this.currentSnapshotIdx];
  }

  undo() {
    if (this.currentSnapshotIdx === null) return null;
    if (this.isFirstSnapshot()) return null;
    --this.currentSnapshotIdx;
    return this.currentChangeHistory.clone();
  }

  redo() {
    if (this.currentSnapshotIdx === null) return null;
    if (this.isLastSnapshot()) return null;
    ++this.currentSnapshotIdx;
    return this.currentChangeHistory.clone();
  }

  prohibitRegistration() {
    this.isRegistrationProhibited = true;
  }

  allowRegistration() {
    this.isRegistrationProhibited = false;
  }
}

Object.defineProperty(
  ChangeHistoryManager,
  'ChangeHistory',
  {
    value: class {
      constructor(audioSegmentSequence) {
        this.audioSegmentSequence = audioSegmentSequence;
      }

      clone() {
        return new ChangeHistoryManager.ChangeHistory(this.audioSegmentSequence.clone());
      }

      isEqualTo(that) {
        if (!this.audioSegmentSequence.isEqualTo(that.audioSegmentSequence)) return false;
        return true;
      }
    },
    writable: true,
  },
)

export default ChangeHistoryManager;