<template>
  <div 
    id="editable-text"
    v-on:focusin="$_onFocusin"
    v-on:focusout="$_onFocusout"
  >
    <input
      ref="inputElement"
      id="text-input"
      class="flex-grow-1"
      autocomplete="off"
      v-bind:disabled="readonly"
      v-bind:readonly="$_apparentlyReadonly"
      v-on:compositionstart="$_onCompositionstart"
      v-on:compositionend="$_onCompositionend"
      v-on:keydown.stop="$_onKeydown"
      v-on:dblclick="$_onDblclick"
      v-on:input="$_validate"
    />
    <template v-if="!simple">
      <template v-if="$data.$_isEditing">
        <v-btn
          icon x-small
          class="flex-grow-0"
          v-on:mousedown="$_finishEditing"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <v-btn
          icon x-small
          class="flex-grow-0"
          v-on:mousedown="$_cancelEditing"
        >
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
      </template>

      <template v-else>
        <v-btn
          icon x-small
          v-if="!readonly"
          v-on:mousedown="$_startEditing"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </template>
  </div>
</template>

<style scoped>
div#editable-text {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: max-content;
}

div#editable-text.simple {
  border-bottom: none !important;
}

div#editable-text.editing {
  border-bottom: 1px solid #000000;
}

div#editable-text {
  border-bottom: 1px solid #dddddd;
}

input#text-input {
  text-overflow: ellipsis;
}
</style>

<script>
import Utils from './modules/Utils.js';

export default {
  model: {
    prop: 'value',
    event: 'update',
  },

  watch: {
    value(value) {
      this.$_updateElement(value);
    },

    '$data.$_isEditing'() {
      this.$_updateElement(this.value);
    },

    simple() {
      this.$_updateElement(this.value);
    },
  },

  mounted() {
    this.$_updateElement(this.value);
  },

  props: {
    value: {},
    readonly: { type: Boolean, default: false },
    prohibitEmpty: { type: Boolean, default: false },
    validator: { type: Function, default: null },
    simple: { type: Boolean, default: false },
  },

  data() {
    return {
      $_isEditing: false,
      $_isValid: true,
      $_isCompositionStarted: false,
    };
  },

  computed: {
    $_apparentlyReadonly() {
      if (this.simple) {
        return this.readonly;
      } else {
        return this.readonly || (!this.$data.$_isEditing);
      }
    },

    $_inputElement() {
      return this.$refs.inputElement;
    },
  },

  methods: {
    $_updateElement(value) {
      this.$_inputElement.value = String(value);
      let valueString = String(value);
      this.$_inputElement.size = (valueString.length < 1)? 1 : valueString.length;
      if (this.simple) {
        this.$el.classList.add('simple');
      } else {
        this.$el.classList.remove('simple')
      }
      if (this.$data.$_isEditing) {
        this.$el.classList.add('editing');
      } else {
        this.$el.classList.remove('editing')
      }
    },

    $_updateValue() {
      let newValue = getNewValue(this);
      this.$_updateElement(newValue);
      if (newValue === this.value) return false;
      this.$emit('update', newValue);
      return true;

      function getNewValue(self) {
        if (self.$data.$_isValid) {
          return self.$_getInputValue();
        } else {
          return self.value;
        }
      }
    },

    $_getInputValue() {
      if (Utils.isNumber(this.value)) {
        return Number(this.$_inputElement.value);
      } else {
        return this.$_inputElement.value;
      }
    },

    $_onFocusin() {
      if (this.simple) {
        this.$_startEditing();
      }
    },

    $_onCompositionstart() {
      this.$data.$_isCompositionStarted = true;
    },

    $_onCompositionend() {
      this.$data.$_isCompositionStarted = false;
    },

    $_onDblclick() {
      if (!this.simple && !this.$data.$_isEditing) {
        this.$_startEditing();
      }
    },

    $_onKeydown(keyboardEvent) {
      if (onKeydown(this, keyboardEvent)) {
        keyboardEvent.preventDefault();
      }

      function onKeydown(self, keyboardEvent) {
        if (self.$data.$_isCompositionStarted) return true;
        if (self.$data.$_isEditing) {
          switch (keyboardEvent.code) {
            case 'Enter':
              if (self.simple) {
                return self.$_updateValue();
              } else {
                return finishEditing(self);
              }
            case 'ArrowUp':
              return incrementInputValue(self);
            case 'ArrowDown':
              return decrementInputValue(self);
            case 'Escape':
              return self.$_cancelEditing();
            default:
              return false;
          }
        } else {
          switch (keyboardEvent.code) {
            case 'Enter':
              if (!self.simple) {
                return startEditing(self);
              }
              break;
            default:
              return false;
          }
        }
      }

      function finishEditing(self) {
        self.$_finishEditing();
        return true;
      }

      function startEditing(self) {
        self.$_startEditing();
        return true;
      }

      function incrementInputValue(self) {
        if (!Utils.isNumber(self.value)) return false;
        self.$_inputElement.value = String(Math.floor(self.$_getInputValue()) + 1);
        return true;
      }

      function decrementInputValue(self) {
        if (!Utils.isNumber(self.value)) return false;
        self.$_inputElement.value = String(Math.floor(self.$_getInputValue()) - 1);
        return true;
      }
    },

    $_onFocusout(focusEvent) {
      if (!this.$data.$_isEditing) return;
      if (focusEvent.relatedTarget !== null) {
        for (let childNode of this.$el.childNodes) {
          if (focusEvent.relatedTarget.isSameNode(childNode)) return;
        }
      }
      this.$_cancelEditing();
    },

    $_startEditing() {
      if (this.readonly) return;
      this.$data.$_isEditing = true;
    },

    $_finishEditing() {
      this.$_updateValue();
      this.$data.$_isEditing = false;
    },

    $_cancelEditing() {
      this.$_updateElement(this.value);
      document.activeElement.blur();
      this.$data.$_isEditing = false;
    },

    $_validate() {
      if (this.validator !== null) {
        this.$data.$_isValid = this.validator(this.$_getInputValue());
      } else {
        this.$data.$_isValid = true;
      }
    },
  },
}
</script>