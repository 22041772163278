class ControlMode {
  constructor(id, iconId, alternativeIconId = null) {
    this.id = id;
    this.iconId = iconId;
    this.alternativeIconId = (alternativeIconId === null)? iconId : alternativeIconId;
  }
}

Object.defineProperty(
  ControlMode,
  'normal',
  {
    value: new ControlMode('normal', 'mdi-cursor-default'),
    writable: false,
  },
);

Object.defineProperty(
  ControlMode,
  'move',
  {
    value: new ControlMode('normal', 'mdi-cursor-move'),
    writable: false,
  },
);

Object.defineProperty(
  ControlMode,
  'divide',
  {
    value: new ControlMode('divide', 'mdi-content-cut'),
    writable: false,
  },
);

Object.defineProperty(
  ControlMode,
  'remove',
  {
    value: new ControlMode('remove', 'mdi-eraser'),
    writable: false,
  },
);

Object.defineProperty(
  ControlMode,
  'zoom',
  {
    value: new ControlMode('zoom', 'mdi-magnify-plus', 'mdi-magnify-minus'),
    writable: false,
  },
);

export default ControlMode;
