import RationalNumber from './RationalNumber.js';
import Constants from './Constants.js';
import AudioSegment from './AudioSegment.js';
import Utils from './Utils.js';

class AudioSegmentSequence {
  constructor(audioSegments = [], timeUnit = AudioSegmentSequence.TimeUnit.millisecond, samplingRate = null) {
    this.audioSegments = audioSegments;
    this.timeUnit = timeUnit;
    this.samplingRate = samplingRate;
  }

  static generate(audioSegmentArgs = [], timeUnit = AudioSegmentSequence.TimeUnit.millisecond, samplingRate = null) {
    return new this(
      audioSegmentArgs.map(([ begin, end ]) => {
        return new AudioSegment(
          RationalNumber.generateFrom(begin),
          RationalNumber.generateFrom(end),
        );
      }),
      timeUnit,
      samplingRate,
    );
  }

  exportAsObject() {
    let obj = new Object();
    obj.audioSegments = this.audioSegments.map(audioSegment => {
      let audioSegmentObj = new Object();
      audioSegmentObj.begin = audioSegment.begin.toNumber();
      audioSegmentObj.end = audioSegment.end.toNumber();
      return audioSegmentObj;
    });
    obj.timeUnit = this.timeUnit.toString();
    obj.samplingRate = this.samplingRate.toNumber();
    return obj;
  }

  clone() {
    return new AudioSegmentSequence(
      this.audioSegments.map(audioSegment => audioSegment.clone()),
      this.timeUnit,
      (this.samplingRate === null)? null : this.samplingRate.clone(),
    );
  }

  isEqualTo(that) {
    if (!this.timeUnit.isEqualTo(that.timeUnit)) return false;
    if ((this.samplingRate instanceof RationalNumber) && (that.samplingRate instanceof RationalNumber)) {
      if (!this.samplingRate.isEqualTo(that.samplingRate)) return false;
    } else {
      if (this.samplingRate !== that.samplingRate) return false;
    }
    if (this.audioSegments.length !== that.audioSegments.length) return false;
    for (let audioSegmentIdx = 0; audioSegmentIdx < this.numAudioSegments; ++audioSegmentIdx) {
      let thisAudioSegment = this.audioSegments[audioSegmentIdx];
      let thatAudioSegment = that.audioSegments[audioSegmentIdx];
      if (!thisAudioSegment.isEqualTo(thatAudioSegment)) return false;
    }
    return true;
  }

  get numAudioSegments() {
    return this.audioSegments.length;
  }

  set samplingRate(samplingRate) {
    if (samplingRate === null) {
      this._samplingRate = null;
    } else {
      if (!(samplingRate instanceof RationalNumber)) {
        samplingRate = RationalNumber.generateFrom(samplingRate);
      }
      this._samplingRate = samplingRate;
      if (this.timeUnit === this.constructor.TimeUnit.sample) {
        this.resampleAudioSegments(samplingRate);
      }
    }
  }
  get samplingRate() { return this._samplingRate }

  validate() {
    if (this.invalidReasons) {
      throw new this.constructor.TypeError(this.invalidReasons.join('/'));
    }
  }

  get invalidReasons() {
    let invalidReasons = new Array();
    let numSegments = this.audioSegments.length;
    let sortedAudioSegments = this.audioSegments.sort((a, b) => (a.begin - b.begin));
    for (let segmentIdx = 0; segmentIdx < numSegments; ++segmentIdx) {
      if (!this.audioSegments[segmentIdx].isEqualTo(sortedAudioSegments[segmentIdx])) {
        invalidReasons.push('begin time is not sorted.');
        break;
      }
    }
    for (let segmentIdx = 0; segmentIdx < (numSegments - 1); ++segmentIdx) {
      let currentAudioSegment = sortedAudioSegments[segmentIdx];
      let nextAudioSegment = sortedAudioSegments[segmentIdx + 1];
      if (currentAudioSegment.end.isGreaterThan(nextAudioSegment.begin)) {
        invalidReasons.push('segment is overwrapped.');
        break;
      }
    }
    for (let audioSegment of this.audioSegments) {
      if (audioSegment.invalidReasons !== null) {
        invalidReasons.push(...audioSegment.invalidReasons);
        break;
      }
    }
    if (invalidReasons.length > 0) {
      return invalidReasons;
    } else {
      return null;
    }
  }

  resampleAudioSegments(targetSamplingRate) {
    console.assert(this.timeUnit === this.constructor.TimeUnit.sample);
    if (this.samplingRate !== null) {
      let rescalingFactor = targetSamplingRate.divide(this.samplingRate);
      for (let audioSegment of this.audioSegments) {
        audioSegment.begin = audioSegment.begin.multiply(rescalingFactor);
        audioSegment.end = audioSegment.end.multiply(rescalingFactor);
      }
    }
  }

  normalize(end, timeUnit, { samplingRate = null } = {}) {
    let filledAudioSegments = new Array();
    let endTimeInThisTimeUnit = this.constructor.convertTime(end, timeUnit, this.timeUnit, { samplingRate });
    let currentTimeInThisTimeUnit = RationalNumber.generateFrom(0);
    for (let audioSegment of this.audioSegments) {
      if (audioSegment.begin.isGreaterThan(currentTimeInThisTimeUnit)) {
        filledAudioSegments.push(new AudioSegment(
          currentTimeInThisTimeUnit,
          audioSegment.begin,
        ));
      }
      let isLastAudioSegment = false;
      if (audioSegment.end.isGreaterThan(endTimeInThisTimeUnit)) {
        audioSegment = audioSegment.clone();
        audioSegment.end = endTimeInThisTimeUnit;
        isLastAudioSegment = true;
      }
      filledAudioSegments.push(audioSegment);
      currentTimeInThisTimeUnit = audioSegment.end;
      if (isLastAudioSegment) break;
    }
    if (currentTimeInThisTimeUnit.isLessThan(endTimeInThisTimeUnit)) {
      filledAudioSegments.push(new AudioSegment(
        currentTimeInThisTimeUnit,
        endTimeInThisTimeUnit,
      ));
    }
    this.audioSegments = filledAudioSegments;
  }

  convertTime(rationalTime, targetTimeUnit) {
    if (targetTimeUnit === this.timeUnit) return rationalTime;
    switch (this.timeUnit) {
      case this.constructor.TimeUnit.second:
        switch (targetTimeUnit) {
          case this.constructor.TimeUnit.millisecond:
            return rationalTime.multiply(Constants.milli);
          case this.constructor.TimeUnit.microsecond:
            return rationalTime.multiply(Constants.micro);
          case this.constructor.TimeUnit.sample:
            if (this.samplingRate === null) return null;
            return rationalTime.multiply(this.samplingRate);
        }
        break;
      case this.constructor.TimeUnit.millisecond:
        switch (targetTimeUnit) {
          case this.constructor.TimeUnit.second:
            return rationalTime.divide(Constants.milli);
          case this.constructor.TimeUnit.microsecond:
            return rationalTime.multiply(Constants.micro).divide(Constants.milli);
          case this.constructor.TimeUnit.sample:
            if (this.samplingRate === null) return null;
            return rationalTime.multiply(this.samplingRate).divide(Constants.milli);
        }
        break;
      case this.constructor.TimeUnit.microsecond:
        switch (targetTimeUnit) {
          case this.constructor.TimeUnit.second:
            return rationalTime.divide(Constants.micro);
          case this.constructor.TimeUnit.millisecond:
            return rationalTime.multiply(Constants.milli).divide(Constants.micro);
          case this.constructor.TimeUnit.sample:
            if (this.samplingRate === null) return null;
            return rationalTime.multiply(this.samplingRate).divide(Constants.micro);
        }
        break;
      case this.constructor.TimeUnit.sample:
        switch (targetTimeUnit) {
          case this.constructor.TimeUnit.second:
            if (this.samplingRate === null) return null;
            return rationalTime.divide(this.samplingRate);
          case this.constructor.TimeUnit.millisecond:
            if (this.samplingRate === null) return null;
            return rationalTime.multiply(Constants.milli).divide(this.samplingRate);
          case this.constructor.TimeUnit.microsecond:
            if (this.samplingRate === null) return null;
            return rationalTime.multiply(Constants.micro).divide(this.samplingRate);
        }
        break;
    }
    return null;
  }

  static convertTime(rationalTime, sourceTimeUnit, targetTimeUnit, { samplingRate = null } = {}) {
    if (sourceTimeUnit === targetTimeUnit) return rationalTime;
    switch (sourceTimeUnit) {
      case this.TimeUnit.second:
        switch (targetTimeUnit) {
          case this.TimeUnit.millisecond:
            return rationalTime.multiply(Constants.milli);
          case this.TimeUnit.microsecond:
            return rationalTime.multiply(Constants.micro);
          case this.TimeUnit.sample:
            return rationalTime.multiply(samplingRate);
        }
        break;
      case this.TimeUnit.millisecond:
        switch (targetTimeUnit) {
          case this.TimeUnit.second:
            return rationalTime.divide(Constants.milli);
          case this.TimeUnit.microsecond:
            return rationalTime.multiply(Constants.micro).divide(Constants.milli);
          case this.TimeUnit.sample:
            return rationalTime.multiply(samplingRate).divide(Constants.milli);
        }
        break;
      case this.TimeUnit.microsecond:
        switch (targetTimeUnit) {
          case this.TimeUnit.second:
            return rationalTime.divide(Constants.micro);
          case this.TimeUnit.millisecond:
            return rationalTime.multiply(Constants.milli).divide(Constants.micro);
          case this.TimeUnit.sample:
            return rationalTime.multiply(samplingRate).divide(Constants.micro);
        }
        break;
      case this.TimeUnit.sample:
        switch (targetTimeUnit) {
          case this.TimeUnit.second:
            if (samplingRate !== null) return rationalTime.divide(samplingRate);
            break;
          case this.TimeUnit.millisecond:
            if (samplingRate !== null) return rationalTime.multiply(Constants.milli).divide(samplingRate);
            break;
          case this.TimeUnit.microsecond:
            if (samplingRate !== null) return rationalTime.multiply(Constants.micro).divide(samplingRate);
            break;
        }
        break;
    }
    return null;
  }

  replaceSegmentBegin(targetAudioSegmentIdx, newBegin, timeUnit) {
    if (Utils.isString(newBegin)) newBegin = Number(newBegin);
    if (!(newBegin instanceof RationalNumber)) newBegin = RationalNumber.generateFrom(newBegin);
    let convertedTime = AudioSegmentSequence.convertTime(
      newBegin,
      timeUnit,
      this.timeUnit,
      { samplingRate: this.samplingRate },
    );
    this.audioSegments[targetAudioSegmentIdx].begin = convertedTime;
    if (targetAudioSegmentIdx > 0) {
      let previousAudioSegmentIdx = targetAudioSegmentIdx - 1;
      this.audioSegments[previousAudioSegmentIdx].end = convertedTime;
    }
  }

  replaceSegmentEnd(targetAudioSegmentIdx, newEnd, timeUnit) {
    if (Utils.isString(newEnd)) newEnd = Number(newEnd);
    if (!(newEnd instanceof RationalNumber)) newEnd = RationalNumber.generateFrom(newEnd);
    let convertedTime = AudioSegmentSequence.convertTime(
      newEnd,
      timeUnit,
      this.timeUnit,
      { samplingRate: this.samplingRate },
    );
    this.audioSegments[targetAudioSegmentIdx].end = convertedTime;
    if (targetAudioSegmentIdx < (this.numAudioSegments - 1)) {
      let nextAudioSegmentIdx = targetAudioSegmentIdx + 1;
      this.audioSegments[nextAudioSegmentIdx].begin = convertedTime;
    }
  }

  generateBeginValidator(targetAudioSegmentIdx, timeUnit) {
    return (beginValue) => {
      try {
        let targetBegin = this.constructor.convertTime(
          RationalNumber.generateFrom(beginValue),
          timeUnit,
          this.timeUnit,
          { samplingRate: this.samplingRate },
        );
        let targetEnd = this.audioSegments[targetAudioSegmentIdx].end;
        if (targetBegin.isGreaterThan(targetEnd)) return false;
        if (targetAudioSegmentIdx > 0) {
          let previousAudioSegmentIdx = targetAudioSegmentIdx - 1;
          let previousBegin = this.audioSegments[previousAudioSegmentIdx].begin;
          let previousEnd = targetBegin;
          if (previousBegin.isGreaterThan(previousEnd)) return false;
        }
        return true;
      } catch (error) {
        return false;
      }
    };
  }

  generateEndValidator(targetAudioSegmentIdx, timeUnit) {
    return (endValue) => {
      try {
        let targetBegin = this.audioSegments[targetAudioSegmentIdx].begin;
        let targetEnd = this.constructor.convertTime(
          RationalNumber.generateFrom(endValue),
          timeUnit,
          this.timeUnit,
          { samplingRate: this.samplingRate },
        );
        if (targetBegin.isGreaterThanOrEqualTo(targetEnd)) return false;
        if (targetAudioSegmentIdx < (this.numAudioSegments - 1)) {
          let nextAudioSegmentIdx = targetAudioSegmentIdx + 1;
          let nextBegin = targetEnd;
          let nextEnd = this.audioSegments[nextAudioSegmentIdx].end;
          if (nextBegin.isGreaterThanOrEqualTo(nextEnd)) return false;
        }
        return true;
      } catch (error) {
        return false;
      }
    };
  }
}

class AudioSegmentTimeUnit {
  constructor(label) {
    this.label = label;
  }

  toString() {
    return this.label;
  }

  isEqualTo(that) {
    if (this.label !== that.label) return false;
    return true;
  }
}

Object.defineProperty(
  AudioSegmentSequence,
  'TimeUnit',
  {
    value: {
      second: new AudioSegmentTimeUnit('Second'),
      millisecond: new AudioSegmentTimeUnit('Millisecond'),
      microsecond: new AudioSegmentTimeUnit('Microsecond'),
      sample: new AudioSegmentTimeUnit('Sample'),
    },
    writable: false,
  },
);

Object.defineProperty(
  AudioSegmentSequence,
  'TypeError',
  {
    value: class AudioSegmentSequenceTypeError extends Error {
      constructor(...args) {
        super(...args);
      }
    },
    writable: false,
  }
);

export { AudioSegmentTimeUnit }
export default AudioSegmentSequence;