<template>
  <canvas>
  </canvas>
</template>

<script>
import Color from '../../modules/Color.js';
import Utils from '../../modules/Utils.js';
import CanvasBase from './mixins/CanvasBase.js';
import AudioSegmentSequence from '../../modules/AudioSegmentSequence.js';

const segmentPaddingPx = 2;

const normalSegmentBorderColor = new Color(190, 210, 220, 0.2);
const selectedSegmentBorderColor = new Color(100, 160, 200, 0.2);

export default {
  mixins: [
    CanvasBase,
  ],

  model: {
    prop: 'audioSegmentSequence',
    event: 'update',
  },

  watch: {
    waveformDigest()          { this.$_setDirty(true) },
    labelSequence()           { this.$_setDirty(true) },
    selectedSegmentIdcs()     { this.$_setDirty(true) },
    canvasBeginSampleOffset() { this.$_setDirty(true) },
    canvasEndSampleOffset()   { this.$_setDirty(true) },
    audioSegmentSequence()    { this.$_setDirty(true) },
  },

  props: {
    audioSegmentSequence:    { type: AudioSegmentSequence },
    labelSequence:           { type: Array },
    selectedSegmentIdcs:     { type: Array, default: null },
    canvasBeginSampleOffset: { type: Number },
    canvasEndSampleOffset:   { type: Number },
    canvasLocalRect:         { type: DOMRect },
  },

  computed: {
    $_canvasLocalYMax() {
      return this.$_canvasHeightPx;
    },

    $_numSamplesInCanvas() {
      return this.canvasEndSampleOffset - this.canvasBeginSampleOffset;
    },

    $_sampleResolution() {
      return this.$_numSamplesInCanvas / this.$_canvasWidthPx;
    },

    $_segmentCanvasLocalRects() {
      return this.audioSegmentSequence.audioSegments.map(
        audioSegment => {
          if ((audioSegment.begin === null) || (audioSegment.end === null)) return null;

          let beginSampleOffset = AudioSegmentSequence.convertTime(
            audioSegment.begin,
            this.audioSegmentSequence.timeUnit,
            AudioSegmentSequence.TimeUnit.sample,
            { samplingRate: this.audioSegmentSequence.samplingRate },
          );
          let beginCanvasLocalSampleOffset = beginSampleOffset.toNumber() - this.canvasBeginSampleOffset;
          let beginCanvasLocalOffsetPx = Math.floor(beginCanvasLocalSampleOffset / this.$_sampleResolution);

          let endSampleOffset = AudioSegmentSequence.convertTime(
            audioSegment.end,
            this.audioSegmentSequence.timeUnit,
            AudioSegmentSequence.TimeUnit.sample,
            { samplingRate: this.audioSegmentSequence.samplingRate },
          );
          let endCanvasLocalSampleOffset = endSampleOffset.toNumber() - this.canvasBeginSampleOffset;
          let endCanvasLocalOffsetPx = Math.floor(endCanvasLocalSampleOffset / this.$_sampleResolution);

          return new DOMRect(
            beginCanvasLocalOffsetPx,
            this.canvasLocalRect.y,
            endCanvasLocalOffsetPx - beginCanvasLocalOffsetPx,
            this.canvasLocalRect.height,
          );
        }
      );
    },
  },

  mounted() {
    this.$emit('register-component-instance', this);
  },

  beforeDestroy() {
    this.$emit('unregister-component-instance');
  },

  methods: {
    draw(canvasElement) {
      let canvasContext = canvasElement.getContext('2d');
      if (!Utils.isNullOrUndefined(this.audioSegmentSequence)) {
        let numSegments = this.audioSegmentSequence.audioSegments.length;
        for (let segmentIdx = 0; segmentIdx < numSegments; ++segmentIdx) {
          let segmentCanvasLocalRect = this.$_segmentCanvasLocalRects[segmentIdx];
          if (segmentCanvasLocalRect === null) return;
          let isSelected = this.selectedSegmentIdcs.includes(segmentIdx);
          let label = this.labelSequence[segmentIdx];
          this.$_drawSegment(canvasContext, label, segmentCanvasLocalRect, isSelected)
        }
      }
    },

    $_drawSegment(canvasContext, label, segmentCanvasLocalRect, isSelected) {
      drawSegmentBase(this, canvasContext, segmentCanvasLocalRect, isSelected);
      drawContentsOnSegment(canvasContext, segmentCanvasLocalRect, label);

      function drawSegmentBase(self, canvasContext, segmentCanvasLocalRect, isSelected) {
        canvasContext.beginPath();
        canvasContext.rect(...self.$_dotByDotOffsetRectArgs(
          segmentCanvasLocalRect.x,
          segmentCanvasLocalRect.y,
          segmentCanvasLocalRect.width,
          segmentCanvasLocalRect.height - 1,
        ));
        if (isSelected) {
          canvasContext.fillStyle = Color.clearBlue.styleString;
        } else {
          canvasContext.fillStyle = Color.lightClearBlue.styleString;
        }
        canvasContext.fill();
        canvasContext.setLineDash([]);
        canvasContext.lineWidth = 1;
        if (isSelected) {
          canvasContext.strokeStyle = selectedSegmentBorderColor;
        } else {
          canvasContext.strokeStyle = normalSegmentBorderColor;
        }
        canvasContext.stroke();
      }

      function drawContentsOnSegment(canvasContext, segmentCanvasLocalRect, label) {
        const segmentFontSizePxMax = 12;
        const segmentTextOffsetPx = 5;
        const segmentTextOmissionIndicator = '...';

        canvasContext.fillStyle = Color.black.styleString;
        canvasContext.textBaseline = 'middle';
        canvasContext.font = 'normal ' + String((textFontSizePx > segmentFontSizePxMax) ? segmentFontSizePxMax : textFontSizePx) + 'px sans-serif';

        let textFontSizePx = segmentCanvasLocalRect.height - 4;
        let textLeftX = segmentCanvasLocalRect.x + segmentTextOffsetPx;
        let textMiddleY = segmentCanvasLocalRect.y + segmentCanvasLocalRect.height / 2;
        let textWidthPxMax = segmentCanvasLocalRect.x + segmentCanvasLocalRect.width - (textLeftX + segmentPaddingPx * 2);
        let textWidthPx = canvasContext.measureText(label).width;
        if (textWidthPx < textWidthPxMax) {
          canvasContext.fillText(label, textLeftX, textMiddleY);
        } else {
          let segmentTextOmissionIndicatorWidthPx = canvasContext.measureText(segmentTextOmissionIndicator).width;
          if (segmentTextOmissionIndicatorWidthPx > textWidthPxMax) {
            canvasContext.fillText(segmentTextOmissionIndicator, textLeftX, textMiddleY, textWidthPxMax);
          } else {
            let subTextLastIdxOfFirstOverflow = searchSubTextLastIdxOfFirstOverflow(canvasContext, label, textWidthPxMax);
            let subTextOfFirstOverflow = label.substring(0, subTextLastIdxOfFirstOverflow);
            let subTextOfFirstOverflowWithOmissionIndicator = subTextOfFirstOverflow + segmentTextOmissionIndicator;
            canvasContext.fillText(subTextOfFirstOverflowWithOmissionIndicator, textLeftX, textMiddleY);
          }
        }

        function searchSubTextLastIdxOfFirstOverflow(canvasContext, text, textWidthPxMax) {
          let isOverflowedAt = (idx) => {
            let subTextWithOmissionIndicator = text.substring(0, idx) + segmentTextOmissionIndicator;
            let subTextWithOmissionIndicatorWidthPx = canvasContext.measureText(subTextWithOmissionIndicator).width;
            return (subTextWithOmissionIndicatorWidthPx > textWidthPxMax);
          };
          let leftIdx = 0;
          let rightIdx = text.length - 2;
          while (leftIdx <= rightIdx) {
            let middleIdx = Math.floor((leftIdx + rightIdx) / 2);
            if (!isOverflowedAt(middleIdx) && isOverflowedAt(middleIdx + 1)) {
              return middleIdx;
            } else {
              if (isOverflowedAt(middleIdx) && isOverflowedAt(middleIdx + 1)) {
                rightIdx = middleIdx - 1;
              } else if (!isOverflowedAt(middleIdx) && !isOverflowedAt(middleIdx + 1)) {
                leftIdx = middleIdx + 1;
              }
            }
          }
          return (text.length - 2);
        }
      }
    },

    getSegmentIdxAt(canvasLocalCoords) {
      for (let segmentIdx = 0; segmentIdx < this.audioSegmentSequence.numAudioSegments; ++segmentIdx) {
        let segmentCanvasLocalRect = this.$_segmentCanvasLocalRects[segmentIdx];
        if (segmentCanvasLocalRect === null) continue;
        if (canvasLocalCoords.isInside(segmentCanvasLocalRect)) return segmentIdx;
      }
      return null;
    },

    getContainingSegmentIdcs(canvasLocalCoordsA, canvasLocalCoordsB) {
      let containingSegmentIdcs = new Array();
      let [ x1, x2 ] = [ canvasLocalCoordsA.x, canvasLocalCoordsB.x ].sort((a, b) => (a - b));
      let [ y1, y2 ] = [ canvasLocalCoordsA.y, canvasLocalCoordsB.y ].sort((a, b) => (a - b));
      let rectAB = new DOMRect(x1, y1, x2 - x1, y2 - y1);
      this.$_segmentCanvasLocalRects.forEach((segmentCanvasLocalRect, segmentIdx) => {
        if (segmentCanvasLocalRect === null) return;
        if (Utils.areRectsOverwrapped(rectAB, segmentCanvasLocalRect)) {
          containingSegmentIdcs.push(segmentIdx);
        }
      });
      return containingSegmentIdcs;
    },
  },
}
</script>
