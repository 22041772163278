import Module from './WaveformDigestEmscripten.mjs'

class SlicedViewIterator {
  constructor(typedArray, numSamplesPerSlice) {
    let iterationCount = 0;
    let numSamples = typedArray.length;
    let numSamplesOfLastSlice = (numSamples - 1) % numSamplesPerSlice + 1;
    let numSlices = ((numSamples - numSamplesOfLastSlice) / numSamplesPerSlice) + 1;
    let typedArrayClass = Object.getPrototypeOf(typedArray);
    let bytesPerSample = typedArrayClass.BYTES_PER_ELEMENT;
    let typedArrayConstructor = typedArrayClass.constructor;

    this.length = numSlices;

    this.next = function() {
      let sliceBeginSampleIdx = iterationCount * numSamplesPerSlice;
      let isLastSlice = (sliceBeginSampleIdx >= (numSlices - 1) * numSamplesPerSlice);
      let done = isLastSlice;
      let numSamplesOfCurrentSlice = (isLastSlice)? numSamplesOfLastSlice : numSamplesPerSlice;
      let value = new typedArrayConstructor(typedArray.buffer, sliceBeginSampleIdx * bytesPerSample, numSamplesOfCurrentSlice);
      ++iterationCount;
      return { done: done, value: value };
    };

    this[Symbol.iterator] = (() => this);
  }
} 

const isLittleEndian = true;
const ieee754DoubleSampleSize = 8;

class WaveformDigest {
  static DigestWaveform = class {
    constructor(min, max, samplingRate) {
      this.min = min;
      this.max = max;
      this.samplingRate = samplingRate;
    }
  }

  constructor(numChannels, numDigestLevels, originalSamplingRate, originalWaveform, digestArray) {
    this.numChannels = numChannels;
    this.numDigestLevels = numDigestLevels;
    this.originalSamplingRate = originalSamplingRate;
    this.originalWaveform = originalWaveform;
    this.digestArray = digestArray;
  }

  static loadFromAudioBuffer(audioBuffer) {
    let numChannels = audioBuffer.numberOfChannels;
    let originalSamplingRate = audioBuffer.sampleRate;
    let originalWaveform = new Array(numChannels);
    let digestArray = new Array();
    for (let channelIdx = 0; channelIdx < numChannels; ++channelIdx) {
      originalWaveform[channelIdx] = audioBuffer.getChannelData(channelIdx);
      assignDigestArrayFromWaveform(
        digestArray,
        originalWaveform[channelIdx],
        originalSamplingRate,
        channelIdx,
        numChannels,
      );
    }
    let numDigestLevels = digestArray.length;
    return new WaveformDigest(
      numChannels,
      numDigestLevels,
      originalSamplingRate,
      originalWaveform,
      digestArray,
    );

    function assignDigestArrayFromWaveform(digestArray, waveform, sampingRate, channelIdx, numChannels) {
      let previousDigestWaveform = new WaveformDigest.DigestWaveform(waveform, waveform, sampingRate);
      let numSamplesPerSlice = 2;
      let numPreviousDigestSamples = waveform.length
      let digestLevelIdx = 0;
      while (numPreviousDigestSamples > 256) {
        let maxArraySliceIterator = new SlicedViewIterator(previousDigestWaveform.max, numSamplesPerSlice);
        let minArraySliceIterator = new SlicedViewIterator(previousDigestWaveform.min, numSamplesPerSlice);
        let numDigestSamples = maxArraySliceIterator.length;
        let digestWaveform = new WaveformDigest.DigestWaveform(
          new Float64Array(numDigestSamples),
          new Float64Array(numDigestSamples),
          previousDigestWaveform.samplingRate / numSamplesPerSlice,
        );
        {
          let idx = 0;
          for (let maxArray of maxArraySliceIterator) {
            let maxOfMaxArray = maxArray[0];
            for (let currentMax of maxArray) {
              if (currentMax > maxOfMaxArray) {
                maxOfMaxArray = currentMax;
              }
            }
            digestWaveform.max[idx] = maxOfMaxArray;
            ++idx;
          }
        }
        {
          let idx = 0;
          for (let minArray of minArraySliceIterator) {
            let minOfMinArray = minArray[0];
            for (let currentMin of minArray) {
              if (currentMin < minOfMinArray) {
                minOfMinArray = currentMin;
              }
            }
            digestWaveform.min[idx] = minOfMinArray;
            ++idx;
          }
        }
        if (digestArray.length >= digestLevelIdx) {
          digestArray.push(new Array(numChannels));
        }
        digestArray[digestLevelIdx][channelIdx] = digestWaveform;
        numPreviousDigestSamples = numDigestSamples;
        previousDigestWaveform = digestWaveform;
        ++digestLevelIdx;
      }
    }
  }

  static async loadFromAudioBufferByWebAssembly(audioBuffer, webAssemblyFileLocation) {
    let numChannels = audioBuffer.numberOfChannels;
    let originalSamplingRate = audioBuffer.sampleRate;
    let originalWaveform = new Array(numChannels);

    for (let channelIdx = 0; channelIdx < numChannels; ++channelIdx) {
      originalWaveform[channelIdx] = audioBuffer.getChannelData(channelIdx);
    }
    let numSamples = originalWaveform[0].length;

    let module = await Module({ locateFile: fileName => webAssemblyFileLocation + '/' + fileName });
    let numEmscriptenHeapBytes = numChannels * numSamples * Float32Array.BYTES_PER_ELEMENT;
    let emscriptenHeapAddress = module._malloc(numEmscriptenHeapBytes);
    let emscriptenHeap = new Float32Array(
      module.HEAPF32.buffer,
      emscriptenHeapAddress,
      numEmscriptenHeapBytes,
    );

    let writeOffset = 0;
    for (let channelIdx = 0; channelIdx < numChannels; ++channelIdx) {
      emscriptenHeap.set(originalWaveform[channelIdx], writeOffset);
      writeOffset += originalWaveform[channelIdx].length;
    }

    let waveformDigestVector = module.generateWaveformDigestData(
      emscriptenHeapAddress,
      originalSamplingRate,
      numChannels,
      numSamples,
    );
    module._free(emscriptenHeap.byteOffset);

    let waveformDigestVectorSize = waveformDigestVector.size();
    let waveformDigestData = new Uint8Array(waveformDigestVectorSize);
    for (let byteOffset = 0; byteOffset < waveformDigestVectorSize; ++byteOffset) {
      waveformDigestData[byteOffset] = waveformDigestVector.get(byteOffset);
    }
    waveformDigestVector.delete();

    return WaveformDigest.load(waveformDigestData);
  }

  static load(data) {
    let readOffset = 0;
    {
      let dataView = new DataView(data.buffer);
      let readUint8 = readData.bind(null, DataView.prototype.getUint8.bind(dataView), 1, null);
      let readUint32 = readData.bind(null, DataView.prototype.getUint32.bind(dataView), 4, isLittleEndian);
      let readInt8 = readData.bind(null, DataView.prototype.getInt8.bind(dataView), 1, null);
      let readInt16 = readData.bind(null, DataView.prototype.getInt16.bind(dataView), 2, isLittleEndian);
      let readInt32 = readData.bind(null, DataView.prototype.getInt32.bind(dataView), 4, isLittleEndian);
      let readInt64 = readData.bind(null, DataView.prototype.getBigInt64.bind(dataView), 8, isLittleEndian);
      let majorVersion = readUint8();
      let minorVersion = readUint8();
      if (majorVersion !== 0) return;
      if (minorVersion < 5) return;
      let alignmentSizePerChannel = readUint8();
      let bitDepth = readUint8();
      let amplitudeMax = null;
      switch (alignmentSizePerChannel) {
      case 8:
        amplitudeMax = BigInt(1) << BigInt(bitDepth - 1);
        break;
      default:
        amplitudeMax = (1 << (bitDepth - 1));
      }
      let numChannels = readUint8();
      let originalSamplingRate = readUint32();
      let globalDigestFactor = readUint8();
      let originalWaveform = null;
      let dataViewGetterFunction = null;
      switch (alignmentSizePerChannel) {
      case 1:
        dataViewGetterFunction = readInt8;
        break;
      case 2:
        dataViewGetterFunction = readInt16;
        break;
      case 4:
        dataViewGetterFunction = readInt32;
        break;
      case 8:
        dataViewGetterFunction = readInt64;
        break;
      default:
        console.error('Unexpected WAVE sample alignment size.');
        return;
      }
      if (globalDigestFactor === 0) {
        let numSamples = readUint32();
        originalWaveform = new Array(numChannels);
        for (let channelIdx = 0; channelIdx < numChannels; ++channelIdx) {
          originalWaveform[channelIdx] = new Float64Array(numSamples * ieee754DoubleSampleSize);
          for (let sampleIdx = 0; sampleIdx < numSamples; ++sampleIdx) {
            let unnormalizedSample = dataViewGetterFunction();
            let normalizedSample = Number(unnormalizedSample / amplitudeMax);
            originalWaveform[channelIdx][sampleIdx] = normalizedSample;
          }
        }
      }
      let numDigestLevels = readUint8();
      let digestArray = new Array(numDigestLevels);
      for (let digestLevel = 0; digestLevel < numDigestLevels; ++digestLevel) {
        let digestFactor = Math.pow(2, globalDigestFactor + digestLevel + 1);
        let samplingRateOfDigest = originalSamplingRate / digestFactor;
        let numSamplesOfDigest = readUint32();
        let allChannelDigestArray = new Array(numChannels);
        for (let channelIdx = 0; channelIdx < numChannels; ++channelIdx) {
          let digestWaveformMin = new Float64Array(numSamplesOfDigest);
          for (let sampleIdx = 0; sampleIdx < numSamplesOfDigest; ++sampleIdx) {
            let unnormalizedSample = dataViewGetterFunction();
            let normalizedSample = Number(unnormalizedSample / amplitudeMax);
            digestWaveformMin[sampleIdx] = normalizedSample;
          }
          let digestWaveformMax = new Float64Array(numSamplesOfDigest);
          for (let sampleIdx = 0; sampleIdx < numSamplesOfDigest; ++sampleIdx) {
            let unnormalizedSample = dataViewGetterFunction();
            let normalizedSample = Number(unnormalizedSample / amplitudeMax);
            digestWaveformMax[sampleIdx] = normalizedSample;
          }
          allChannelDigestArray[channelIdx] = new WaveformDigest.DigestWaveform(digestWaveformMin, digestWaveformMax, samplingRateOfDigest);
        }
        digestArray[digestLevel] = allChannelDigestArray;
      }
      return new WaveformDigest(numChannels, numDigestLevels, originalSamplingRate, originalWaveform, digestArray);
    }

    function readData(dataViewGetterFunction, readSize, isLittleEndian) {
      let ret = null;
      if (isLittleEndian) {
        ret = dataViewGetterFunction(readOffset, isLittleEndian);
      } else {
        ret = dataViewGetterFunction(readOffset);
      }
      readOffset += readSize;
      return ret;
    }
  }

  getDigest(minimumSamplingRate) {
    if (minimumSamplingRate > this.digestArray[0][0].samplingRate) {
      if (this.originalWaveform) {
        return null;
      } else {
        return this.digestArray[0];
      }
    }
    let numDigestLevels = this.numDigestLevels;
    for (let digestLevel = 1; digestLevel < numDigestLevels; ++digestLevel) {
      if (this.digestArray[digestLevel][0].samplingRate < minimumSamplingRate) {
        return this.digestArray[digestLevel - 1];
      }
    }
    return this.digestArray[numDigestLevels - 1];
  }
}

export default WaveformDigest;