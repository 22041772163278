<template>
    <v-main>
        <page-toolbar
            ref="toolbar"
            id="toolbar"
            title="Create Template"
            previous-page-label="Template Top"
        >
            <template #contents>
                <v-btn
                    text
                    color="indigo"
                    @click="$refs.dialogCreateTemplate.show()"
                >
                    <v-icon left>mdi-check</v-icon>Choose this preset
                </v-btn>
            </template>
        </page-toolbar>

        <div class="d-flex">
            <v-list id="presets-list" dense class="pt-0" :style="styles.presetsList">
                <v-list-item-group color="indigo">
                    <template v-for="categoryItem, i in presetCategoryItems">
                        <v-subheader class="font-weight-bold grey lighten-3" :key="`preset-category-subheader-${i}`">
                            <v-icon small left v-text="categoryItem.icon"></v-icon>
                            {{ categoryItem.name }}
                        </v-subheader>
                        <v-list-item link
                            v-for="presetItem, j in categoryItem.presets"
                            :key="`preset-${i}-${j}`"
                            @click="setPresetSelection(presetItem)"
                        >
                            <v-list-item-content>
                                <span v-if="presetItem.component">{{ presetItem.name }}</span>
                                <span v-else class="text-decoration-line-through">{{ presetItem.name }}</span>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
            <v-card tile id="nav-collapse-button" :style="styles.navCollapseBtn">
                <v-btn large text @click="navCollapsed = !navCollapsed"><v-icon large>{{ this.navCollapseBtnIcon }}</v-icon></v-btn>
            </v-card>
            <div
                id="component"
                :style="styles.component"
            >
                <component
                    :is="component"
                    @submit="showSubmitResults"
                    @set-nano-props="updateNanoProps"
                    @response-update="updateNanoAnswers"
                ></component>
            </div>
        </div>

        <response-navigation-drawer
            :nano-props="nanoProps"
            :nano-answers="nanoAnswers"
        ></response-navigation-drawer>

        <dialog-create-template
            ref="dialogCreateTemplate"
            :client="client"
            :project-name="projectName"
            :preset-group-name="presetGroupName"
            :preset-name="presetName"
            @complete="goBackToTemplatesPage"
        ></dialog-create-template>

        <dialog-submit-response
            ref="dialogSubmitResponse"
            :nano-answers="nanoAnswers"
        ></dialog-submit-response>
    </v-main>
</template>
<script>
import PageToolbar from '@/components/ui/PageToolbar'
import presetCategoryItems from './PresetCategoryItems.js'
import DialogCreateTemplate from './DialogCreateTemplate'
import ResponseNavigationDrawer from './ResponseNavigationDrawer.vue'
import DialogSubmitResponse from './DialogSubmitResponse.vue'

const navigationBarWidthDefault = '250px';

export default {
    components: {
        PageToolbar,
        DialogCreateTemplate,
        ResponseNavigationDrawer,
        DialogSubmitResponse
    },
    data: () => ({
        presetCategoryItems,
        component: null,
        presetGroupName: '',
        presetName: '',
        toolbarHeight: '48px',
        navigationBarWidth: navigationBarWidthDefault,
        navCollapsed: false,
        navCollapseBtnIcon: 'mdi-chevron-left',

        nanoProps: {},
        nanoAnswers: {},
        sentNanoAnswers: {},
    }),
    props: ['client', 'projectName'],
    computed: {
        styles() {
            return {
                presetsList: {
                    top: this.toolbarHeight,
                    height: `calc(100% - ${this.toolbarHeight})`,
                    width: this.navigationBarWidth,
                },
                component: {
                    top: this.toolbarHeight,
                    left: this.navigationBarWidth,
                    width: `calc(100% - ${this.navigationBarWidth})`,
                    height: `calc(100% - ${this.toolbarHeight})`,
                },
                navCollapseBtn: {
                    left: this.navigationBarWidth,
                }
            }
        },
    },
    methods: {
        updateNanoProps($event) {
            this.nanoProps = $event;
        },
        updateNanoAnswers($event) {
            this.nanoAnswers = $event;
        },
        showSubmitResults($event) {
            this.sentNanoAnswers = $event;
            this.$refs.dialogSubmitResponse.show();
        },
        setPresetSelection(item) {
            this.component = item.component;
            this.presetGroupName = item.presetGroupName;
            this.presetName = item.presetName;
        },
        goBackToTemplatesPage() {
            window.location.href = '/console/template';
        }
    },
    watch: {
        navCollapsed(collapsed) {
            if(collapsed) {
                this.navigationBarWidth = '0px';
                this.navCollapseBtnIcon = 'mdi-chevron-right';
            } else {
                this.navigationBarWidth = navigationBarWidthDefault;
                this.navCollapseBtnIcon = 'mdi-chevron-left';
            }
        }
    },
    mounted() {
        this.navigationBarWidth = navigationBarWidthDefault;
        this.toolbarHeight = this.$refs.toolbar.$el.style.height;
    },
}
</script>
<style scoped>
#toolbar {
    position: fixed;
    width: 100%;
    z-index: 3;
}
#presets-list {
    position: absolute;
    left: 0;
    z-index: 2;
    overflow-y: scroll;
    border-right: 1px solid #ddd;
    padding-right: 0;
}
#component {
    position: absolute;
    overflow-y: scroll;
    z-index: 1;
    max-width: none;
}
#nav-collapse-button {
    position: absolute;
    bottom: 2px;
    height: 44px;
    width: 44px;
    z-index: 2;
}
#nav-collapse-button > button {
    padding: 0;
    display: block;
    min-width: 0;
    width: 100%;
}
</style>