export default [
    {
        name: 'Basic',
        icon: '',
        presets: [
            {
                name: 'Blank',
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Blank/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_Blank',
            },
        ]
    },
    {
        name: 'Survey',
        icon: 'mdi-pencil',
        presets: [
            {
                name: 'Single Question',
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_SimpleSurvey/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_SimpleSurvey',
            },
            {
                name: 'Multiple Questions',
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_Survey/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_Survey',
            },
        ]
    },
    {
        name: 'Audio',
        icon: 'mdi-headphones',
        presets: [
            {
                name: 'Classification',
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_AudioClassification/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_AudioClassification',
            },
            {
                name: 'Pairwise Comparison',
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_AudioComparison/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_AudioComparison',
            },
            {
                name: 'Segmentation',
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_AudioSegmentation/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_AudioSegmentation',
            },
        ]
    },
    {
        name: 'Image',
        icon: 'mdi-image',
        presets: [
            {
                name: 'Classification',
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_ImageClassification/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_ImageClassification',
            },
            {
                name: 'Pairwise Comparison',
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_ImageComparison/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_ImageComparison',
            },
            {
                name: 'Ranking',
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_ImageRanking/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_ImageRanking',
            },
            {
                name: 'Bounding Box',
                //component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_BoundingBox/Main.vue').default,
                //presetGroupName: 'VueComponent',
                //presetName: 'Vuetify_BoundingBox',
            },
        ]
    },
    {
        name: 'Text',
        icon: 'mdi-format-text',
        presets: [
            {
                name: 'Classification',
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_TextClassification/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_TextClassification',
            },
            //{
            //    name: 'Named Entity Labeling',
            //    component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_TextNamedEntityLabeling/Main.vue').default,
            //    presetGroupName: 'VueComponent',
            //    presetName: 'Vuetify_TextNamedEntityLabeling',
            //},
        ]
    },
    {
        name: 'Video',
        icon: 'mdi-filmstrip',
        presets: [
            {
                name: 'Classification',
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_VideoClassification/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_VideoClassification',
            },
            {
                name: 'Timeline Segment Labeling',
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_VideoTimelineSegmentLabeling/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_VideoTimelineSegmentLabeling',
            }
        ]
    }
]