<template>
  <v-toolbar id="waveform-view-toolbar">
    <v-btn
      outlined small depressed class="my-1 mr-2 py-1"
      v-bind:disabled="isUndoDisabled"
      v-on:click="undo"
    >
      <v-icon>mdi-undo</v-icon>
    </v-btn>

    <v-btn
      outlined small depressed class="my-1 mr-2 py-1"
      v-bind:disabled="isRedoDisabled"
      v-on:click="redo"
    >
      <v-icon>mdi-redo</v-icon>
    </v-btn>

    <v-btn
      outlined small depressed class="my-1 mr-2 py-1"
      v-bind:input-value="isAutoFollowLoopDefinitionEnabled"
      v-on:click="$_toggleIsAutoFollowLoopDefinitionEnabled"
    >
      <v-icon>mdi-restart</v-icon>
    </v-btn>

    <v-btn
      outlined small depressed class="my-1 mr-2 py-1"
      v-bind:disabled="$_isCopyAudioSegmentLabelDisabled"
      v-on:click="$_copyAudioSegmentLabel"
    >
      <v-icon>mdi-content-copy</v-icon>
    </v-btn>

    <v-btn
      outlined small depressed class="my-1 mr-2 py-1"
      v-bind:disabled="$_isPasteAudioSegmentLabelDisabled"
      v-on:click="$_pasteAudioSegmentLabel"
    >
      <v-icon>mdi-content-paste</v-icon>
    </v-btn>

    <v-btn-toggle
      dense mandatory class="my-1"
      v-model="$_selectedControlModeIdx"
    >
      <v-btn
        outlined small class="py-1"
        v-for="(controlMode, idx) of $_allControlModes"
        v-bind:key="idx"
      >
        <v-icon>
          <template v-if="shiftKeyPressed">
            {{ controlMode.alternativeIconId }}
          </template>
          <template v-else>
            {{ controlMode.iconId }}
          </template>
        </v-icon>
      </v-btn>
    </v-btn-toggle>
  </v-toolbar>
</template>

<style scoped>
#waveform-view-toolbar {
  user-select: none;
  height: fit-content !important;
}

#waveform-view-toolbar >>> * {
  height: auto !important;
  flex-wrap: wrap !important;
  white-space: normal !important;
}
</style>

<script>
import ControlMode from '../modules/ControlMode.js';

export default {
  model: {
    prop: 'controlMode',
    event: 'update',
  },

  watch: {
    controlMode(controlMode) {
      this.$data.$_selectedControlModeIdx = this.$_allControlModes.findIndex(x => (x === controlMode));
    },
  },

  props: {
    controlMode:                       { type: ControlMode },
    shiftKeyPressed:                   { type: Boolean },
    isSingleAudioSegmentSelected:      { type: Boolean },
    isAnyAudioSegmentSelected:         { type: Boolean },
    isAudioSegmentLabelCopied:         { type: Boolean },
    isAutoFollowLoopDefinitionEnabled: { type: Boolean },
    isUndoDisabled:                    { type: Boolean },
    isRedoDisabled:                    { type: Boolean },
  },

  computed: {
    $_selectedControlModeIdx: {
      get() {
        return this.$_allControlModes.findIndex(x => (x === this.controlMode));
      },

      set(selectedControlModeIdx) {
        this.$emit('update', this.$_allControlModes[selectedControlModeIdx]);
      },
    },

    $_allControlModes() {
      return [
        ControlMode.normal,
        ControlMode.move,
        ControlMode.divide,
        ControlMode.remove,
        ControlMode.zoom,
      ];
    },

    $_isCopyAudioSegmentLabelDisabled() { return !this.isSingleAudioSegmentSelected },

    $_isPasteAudioSegmentLabelDisabled() {
      if (!this.isAudioSegmentLabelCopied) return true;
      if (!this.isAnyAudioSegmentSelected) return true;
      return false;
    },
  },

  inject: [
    'undo',
    'redo',
  ],
  
  methods: {
    onKeydown(keyboardEvent) {
      switch (keyboardEvent.code) {
        case 'Escape':
          return setControlModeNormal(this);
        case 'KeyZ':
          if (keyboardEvent.ctrlKey)  {
            if (keyboardEvent.shiftKey) {
              return this.redo();
            } else {
              return this.undo();
            }
          }
          return false;
        case 'KeyC':
          if (keyboardEvent.ctrlKey)  {
            return this.$_copyAudioSegmentLabel();
          }
          return false;
        case 'KeyV':
          if (keyboardEvent.ctrlKey)  {
            return this.$_pasteAudioSegmentLabel();
          }
          return false;
        default:
          return false;
      }

      function setControlModeNormal(self) {
        if (self.controlMode === ControlMode.normal) return false;
        self.$emit('update', ControlMode.normal);
        return true;
      }
    },

    $_copyAudioSegmentLabel() {
      if (this.$_isCopyAudioSegmentLabelDisabled) return false;
      this.$emit('copy-audio-segment-label');
      return true;
    },

    $_pasteAudioSegmentLabel() {
      if (this.$_isPasteAudioSegmentLabelDisabled) return false;
      this.$emit('paste-audio-segment-label');
      return true;
    },

    $_toggleIsAutoFollowLoopDefinitionEnabled() {
      if (this.isAutoFollowLoopDefinitionEnabled) {
        this.$emit('disable-auto-follow-loop-definition');
      } else {
        this.$emit('enable-auto-follow-loop-definition');
      }
    },
  }
}
</script>