<template>
  <v-toolbar>
    <audio-player-controller
      ref="audioPlayerController"
      v-bind:duration-sec="audioBuffer.duration"
      v-bind:play-time-sec="$data.$_playTimeSec"
      v-bind:is-playing="$data.$_isPlaying"
      v-bind:is-loop-enabled="isLoopEnabled"
      v-bind:loop-definition="$_loopDefinition"
      v-on:seek-in-sec="$_seekInSec"
      v-on:play="$_play"
      v-on:pause="$_pause"
      v-on:enable-loop="enableLoopPlayback"
      v-on:disable-loop="disableLoopPlayback"
    >
    </audio-player-controller>
  </v-toolbar>
</template>

<script>
import AudioPlayerController from './AudioPlayer/AudioPlayerController.vue';
import AudioPlaybackLoopDefinition from './AudioPlayer/modules/AudioPlaybackLoopDefinition.js';

export default {
  components: {
    AudioPlayerController,
  },

  watch: {
    async '$data.$_isPlaying'(isPlaying) {
      if (isPlaying) {
        await this.$_resume();
      } else {
        await this.$_suspend();
      }
    },

    async isLoopEnabled(isLoopEnabled) {
      if (this.$_audioBufferSourceNode === null) return;
      if (!isLoopEnabled) {
        this.$data.$_originOfCurrentTime = this.audioContext.currentTime - this.$_getPlayTimeSec();
      } else {
        await this.$_seekInSec(this.$_loopDefinition.beginTimeSec);
      }
      this.$_audioBufferSourceNode.loop = isLoopEnabled;
    },

    async $_loopDefinition(loopDefinition) {
      if (this.isLoopEnabled) {
        await this.$_seekInSec(loopDefinition.beginTimeSec);
      }
    },
  },

  props: {
    audioBuffer:    { type: AudioBuffer },
    audioContext:   { type: AudioContext },
    loopDefinition: { type: AudioPlaybackLoopDefinition },
    isLoopEnabled:  { type: Boolean },
  },

  data() {
    return {
      $_previousTimeSec: 0,
      $_playTimeSec: 0,
      $_isPlaying: false,

      $_audioBufferSourceNodePool: new Object(),
      $_latestAudioBufferSourceNodeId: null,
      $_originOfCurrentTime: 0,
    };
  },

  computed: {
    $_audioBufferSourceNode() {
      let numAudioBufferSourceNodes = Object.values(this.$data.$_audioBufferSourceNodePool).length;
      if (numAudioBufferSourceNodes === 0) return null;
      return this.$data.$_audioBufferSourceNodePool[this.$data.$_latestAudioBufferSourceNodeId];
    },

    $_loopDefinition() {
      if (this.$_isApplicableLoopDefinition) {
        return this.loopDefinition;
      } else {
        return new AudioPlaybackLoopDefinition(0, this.audioBuffer.duration);
      }
    },

    $_isApplicableLoopDefinition() {
      if (this.loopDefinition === null) return false;
      if (this.loopDefinition.beginTimeSec < this.loopDefinition.endTimeSec) return true;
      return false;
    },
  },

  mounted() {
    this.$emit('register-component-instance', this);
  },

  async beforeDestroy() {
    await this.$_stopAllAudioBufferSourceNodes();
    this.$emit('unregister-component-instance');
  },

  inject: [
    'enableLoopPlayback',
    'disableLoopPlayback',
  ],

  methods: {
    /* public */
    onRequestAnimationFrame() {
      this.$_update();
    },

    onKeydown(keyboardEvent) {
      switch (keyboardEvent.code) {
        case 'Space':
          togglePlayAndPause(this);
          return true;
        default:
          return false;
      }

      function togglePlayAndPause(self) {
        (self.$data.$_isPlaying)? self.$_pause() : self.$_play();
      }
    },

    onMousemove(mouseEvent) {
      this.$refs.audioPlayerController.onMousemove(mouseEvent);
    },

    onMouseup(mouseEvent) {
      this.$refs.audioPlayerController.onMouseup(mouseEvent);
    },

    /* private */
    async $_suspend() {
      switch (this.audioContext.state) {
        case 'running':
          await this.audioContext.suspend();
          break;
      }
    },

    async $_resume() {
      if (this.$_audioBufferSourceNode === null) {
        await this.$_seekInSec(0);
      }
      switch (this.audioContext.state) {
        case 'suspended':
          await this.audioContext.resume();
          break;
      }
    },

    async $_seekInSec(seekTimeSec) {
      let isRunnningOnSeek = (this.$data.$_isPlaying);
      if (Object.keys(this.$data.$_audioBufferSourceNodePool).length > 0) {
        switch (this.audioContext.state) {
          case 'suspended':
            await this.$_resume();
            break;
        }
        await this.$_stopAllAudioBufferSourceNodes();
      }
      await this.$_suspend();
      let newAudioBufferSourceNodeId = String(new Date().getTime());
      this.$data.$_latestAudioBufferSourceNodeId = newAudioBufferSourceNodeId;
      this.$set(this.$data.$_audioBufferSourceNodePool, newAudioBufferSourceNodeId, this.audioContext.createBufferSource());
      this.$_audioBufferSourceNode.buffer = this.audioBuffer;
      this.$_audioBufferSourceNode.connect(this.audioContext.destination);
      this.$_audioBufferSourceNode.onended = () => {
        this.$data.$_originOfCurrentTime = this.audioContext.currentTime;
        delete this.$delete(this.$data.$_audioBufferSourceNodePool, newAudioBufferSourceNodeId);
        this.$data.$_isPlaying = false;
      };
      this.$_audioBufferSourceNode.loop = this.isLoopEnabled;
      this.$_audioBufferSourceNode.loopStart = this.$_loopDefinition.beginTimeSec;
      this.$_audioBufferSourceNode.loopEnd = this.$_loopDefinition.endTimeSec;
      if (this.isLoopEnabled) {
        seekTimeSec = this.$_loopDefinition.beginTimeSec;
      }
      this.$_audioBufferSourceNode.start(0, seekTimeSec);
      this.$data.$_originOfCurrentTime = this.audioContext.currentTime - seekTimeSec;
      if (isRunnningOnSeek) await this.$_resume();
    },

    $_getPlayTimeSec() {
      let offsetTime = this.audioContext.currentTime - this.$data.$_originOfCurrentTime;
      if (this.$_audioBufferSourceNode === null) return offsetTime;
      if (this.$_audioBufferSourceNode.loop) {
        if (offsetTime > this.$_audioBufferSourceNode.loopStart) {
          let loopDuration = this.$_audioBufferSourceNode.loopEnd - this.$_audioBufferSourceNode.loopStart;
          let offsetFromLoopStart = (offsetTime - this.$_audioBufferSourceNode.loopStart) % loopDuration;
          offsetTime = this.$_audioBufferSourceNode.loopStart + offsetFromLoopStart;
        }
      }
      return offsetTime;
    },

    $_play() {
      this.$data.$_isPlaying = true;
    },

    $_pause() {
      this.$data.$_isPlaying = false;
    },

    $_update() {
      let playTimeSec = this.$_getPlayTimeSec();
      this.$data.$_previousTimeSec = this.$data.$_playTimeSec;
      this.$data.$_playTimeSec = playTimeSec;
      this.$emit('update-play-time', playTimeSec);
    },

    async $_stopAllAudioBufferSourceNodes() {
      await Promise.all(
        Object.keys(this.$data.$_audioBufferSourceNodePool).map(audioBufferSourceNodeId => {
          return new Promise(resolve => {
            let audioBufferSourceNode = this.$data.$_audioBufferSourceNodePool[audioBufferSourceNodeId];
            audioBufferSourceNode.onended = () => {
              this.$delete(this.$data.$_audioBufferSourceNodePool, audioBufferSourceNodeId);
              resolve();
            };
            audioBufferSourceNode.stop();
          })
        }),
      );
    }
  },
}
</script>