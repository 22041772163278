<template>     
    <div class="pa-6">
        <t-audio-segmentation
            v-model="audioSegmentSequence"
            v-bind:label-sequence.sync="nano.ans.labelSequence"
            v-bind:audio-url="nano.props.audioUrl"
            v-bind:label-options="labelOptions"
        >
        </t-audio-segmentation>
        <div class="text-end">
            <v-btn color="primary" @click="submit()">Submit</v-btn>
        </div>
    </div>
</template>

<script>     
import TAudioSegmentation from '@/components/template-ui/TAudioSegmentation.vue';
import AudioSegmentSequence from '@/components/template-ui/TAudioSegmentation/modules/AudioSegmentSequence.js';
import nanoMixIn from "@/mixins/nano";

export default {
    components: { TAudioSegmentation },

    mixins: [ nanoMixIn ],

    data: () => ({
        defaultNanoProps: {
            audioUrl: '/static/samples/audios/utterance-1.wav',
        },
        defaultNanoAnswers: {
            segments: null,
            labelSequence: [ "silB", "ky", "o:", "w", "a", "i", "i", "t", "e", "N", "k", "i", "d", "a", "silE" ]
        },
        audioSegmentSequence: AudioSegmentSequence.generate([
            [ 0, 169 ],
            [ 169, 241 ],
            [ 241, 434 ],
            [ 434, 463 ],
            [ 463, 590 ],
            [ 590, 748 ],
            [ 748, 920 ],
            [ 920, 944 ],
            [ 944, 1047 ],
            [ 1047, 1146 ],
            [ 1146, 1192 ],
            [ 1192, 1243 ],
            [ 1243, 1291 ],
            [ 1291, 1414 ],
            [ 1414, 1723 ],
        ])
    }),
    computed: {
        labelOptions() { return [ 'silB', 'silE' ] },
    },
    methods: {
        exportSequence() {
            this.nano.ans.segments = this.audioSegmentSequence.exportAsObject();
        }
    },
    watch: {
        audioSegmentSequence() {
            this.exportSequence();
        },
    },
};
</script>

<style>
</style>
